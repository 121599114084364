import { Modal, Button } from "antd";
import React, { useState } from "react";
import { Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import ZSLogo from "../zslogo.png";
import payerconnect from "../payerconnect.png";
import FAQ from "./FAQ";

export default function NavBar({ SurveyStore, setOnLoginPage, setIsLoading }) {
  const [isModalVisible, setIsModalVisible] = useState(false); //states to manage faq modal
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div
      className="bg-black flex sticky top-0 z-50"
      style={{ height: "4.1rem" }}
    >
      <div className="ml-5 w-96">
        <a
          // delete store content when redirecting to homepage
          onClick={() => SurveyStore.deleteStoreData()}
          className="flex items-center nav-link"
          href="/"
        >
          {/* <Link
          
          to="/currentsurveys"
          className="flex items-center"
        > */}
          <div className="w-1/8 mr-2 my-1.5">
            <img
              src={ZSLogo}
              alt="ZS"
              style={{
                width: "2.2rem",
                padding: "0.3rem",
                marginLeft: "0.2rem",
              }}
            />
          </div>
          <div className="flex mr-2 text-2xl text-white">
            <p className="text-white mb-0">ZS&nbsp;</p>
            <b>Payer&nbsp;Connect</b>
            <p
              className="font-bold ml-1"
              style={{
                verticalAlign: "super",
                fontSize: "0.6rem",
                marginTop: "-0.3rem",
              }}
            >
              {" "}
              TM
            </p>
          </div>
          <div className="w-1/6 my-1.5">
            <img
              src={payerconnect}
              alt="payerconnect"
              style={{
                width: "3.2rem",
                height: "2.7rem",
                // padding: "0.3rem",
                marginLeft: "0.2rem",
              }}
            />
          </div>
          {/* </Link> */}
        </a>
      </div>
      <div className="flex w-4/5 justify-end py-5 mr-5">
        {JSON.parse(sessionStorage.getItem("appInfo"))["isAdmin"] ? (
          <div style={{ cursor: "pointer" }}>
            {/* <a className="nav-link" href="/admin"> */}
            <Tooltip title="Admin">
              <i
                onClick={() => {
                  SurveyStore.increaseNavigationCounter();
                  navigate("/admin");
                }}
                className="fa-solid fa-user-gear fa-xl mx-2"
                style={{ color: "whitesmoke" }}
              ></i>
            </Tooltip>
            {/* </a> */}
          </div>
        ) : (
          ""
        )}

        <div>
          <a
            onClick={() => SurveyStore.deleteStoreData()}
            className="nav-link"
            href="/"
          >
            <Tooltip title="Home">
              <i
                className="fa fa-home fa-xl mx-2"
                style={{ color: "whitesmoke" }}
                aria-hidden="true"
              ></i>
            </Tooltip>
          </a>
        </div>
        <div>
          <a
            onClick={() => SurveyStore.deleteStoreData()}
            className="nav-link"
            href="/"
          >
            <Tooltip title="Logout">
              <i
                className="fa-solid fa-power-off mx-2 fa-xl"
                style={{ color: "whitesmoke" }}
                aria-hidden="true"
                onClick={() => {
                  // set clientId to 0 when redirecting to homepage and clear the session storage
                  SurveyStore.setClientId(0);
                  setOnLoginPage(false);
                  sessionStorage.clear();
                  setIsLoading(true);
                }}
              ></i>
            </Tooltip>
          </a>
        </div>
        <div>
          {/* <a className="nav-link" href="/currentsurveys"> */}
          <Tooltip title="Help">
            <i
              className="fa fa-circle-question fa-xl mx-2"
              style={{
                color: "whitesmoke",
                cursor: "pointer",
                // fontSize: "1.5rem",
              }}
              aria-hidden="true"
              onClick={showModal}
            ></i>
          </Tooltip>
          {/* </a> */}
        </div>
      </div>
      <Modal
        style={{ minWidth: "60rem" }}
        title="Help"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <FAQ onPayerScreen={false} />
      </Modal>
    </div>
  );
}
