import "./stepper.css";
import { CheckCircleOutlined, RightOutlined } from "@ant-design/icons";

export default function Stepper(props) {
  // props.current specifies on which screen we are
  // 0 >> Create Survey Fields
  // 1 >> Create Survey Questions
  // 2 >> Review Survey
  // 3 >> Respondent Selection
  // render the stepper icons and parts accordingly
  return (
    <div
      className="flex banner justify-center pl-12 "
      style={{ textAlign: "center", minHeight: "5rem" }}
    >
      {props.current === 0 ? (
        <div className="steps w-4/5">
          <div className="step-item flex w-full items-center">
            <div
              className="flex pb-3"
              style={{
                borderBottom: "solid",
                borderBottomColor: "rgb(237,125,49)",
              }}
            >
              <div className="step-button border-2 items-center rounded-full  activeStep">
                <p className="mt-1 ">1</p>
              </div>
              <div className="px-2 text-white mt-2 mr-2  ">Survey Fields</div>
            </div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div className="step-button text-center text-white border-gray-300 border-2 rounded-full ">
              <p className="mt-1 ">2</p>
            </div>
            <div className="px-2 mr-2 text-white ">Survey Questions</div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div className="step-button text-center text-white border-gray-300 border-2 rounded-full ">
              <p className="mt-1 ">3</p>
            </div>
            <div className="px-2 mr-2 text-white ">Review Survey</div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div className="step-button text-center text-white border-gray-300 border-2 rounded-full ">
              <p className="mt-1 ">4</p>
            </div>
            <div className="px-2 mr-2 text-white ">Respondent Selection</div>
          </div>
        </div>
      ) : props.current === 1 ? (
        <div className="steps w-4/5">
          <div className="step-item flex w-full items-center">
            <div className="text-center rounded-full  text-white">
              <CheckCircleOutlined
                style={{ fontSize: "35px", color: "whitesmoke" }}
              />
            </div>
            <div className="px-2 mr-2 text-white ">Survey Fields</div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div
              className="flex pb-3"
              style={{
                borderBottom: "solid",
                borderBottomColor: "rgb(237,125,49)",
              }}
            >
              <div className="step-button text-center activeStep  text-white rounded-full ">
                <p className="mt-1">2</p>
              </div>
              <div className="px-2 mt-2 mr-2 text-white ">Survey Questions</div>
            </div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div className="step-button text-center text-white border-gray-300 border-2 rounded-full ">
              <p className="mt-1 ">3</p>
            </div>
            <div className="px-2 mr-2 text-white ">Review Survey</div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div className="step-button text-center text-white border-gray-300 border-2 rounded-full ">
              <p className="mt-1 ">4</p>
            </div>
            <div className="px-2 mr-2 text-white ">Respondent Selection</div>
          </div>
        </div>
      ) : props.current === 2 ? (
        <div className="steps w-4/5">
          <div className="step-item flex w-full items-center">
            <div className="text-center rounded-full   text-white">
              <CheckCircleOutlined
                style={{ fontSize: "35px", color: "whitesmoke" }}
              />
            </div>
            <div className="px-2 mr-2 text-white ">Survey Fields</div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div className="text-center text-white rounded-full ">
              <CheckCircleOutlined
                style={{ fontSize: "35px", color: "whitesmoke" }}
              />
            </div>
            <div className="px-2 mr-2 text-white ">Survey Questions</div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div
              className="flex pb-3"
              style={{
                borderBottom: "solid",
                borderBottomColor: "rgb(237,125,49)",
              }}
            >
              <div className="step-button text-center activeStep border-2 text-white rounded-full ">
                <p className="mt-1 ">3</p>
              </div>
              <div className="px-2 mt-2 mr-2 text-white ">Review Survey</div>
            </div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div className="step-button text-center text-white border-gray-300 border-2 rounded-full ">
              <p className="mt-1 ">4</p>
            </div>
            <div className="px-2 mr-2 text-white ">Respondent Selection</div>
          </div>
        </div>
      ) : (
        <div className="steps w-4/5">
          <div className="step-item flex w-full items-center">
            <div className="text-center rounded-full  text-white">
              <CheckCircleOutlined
                style={{ fontSize: "35px", color: "whitesmoke" }}
              />
            </div>
            <div className="px-2 mr-2 text-white ">Survey Fields</div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div className="text-center text-white rounded-full ">
              <CheckCircleOutlined
                style={{ fontSize: "35px", color: "whitesmoke" }}
              />
            </div>
            <div className="px-2 mr-2 text-white ">Survey Questions</div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div className="text-center  text-white rounded-full ">
              <CheckCircleOutlined
                style={{ fontSize: "35px", color: "whitesmoke" }}
              />
            </div>
            <div className="px-2 mr-2 text-white ">Review Survey</div>
            <RightOutlined
              className="ml-16 stepperIcon"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="step-item flex w-full items-center">
            <div
              className="flex pb-3"
              style={{
                borderBottom: "solid",
                borderBottomColor: "rgb(237,125,49)",
              }}
            >
              <div className="step-button text-center activeStep border-2 text-white rounded-full ">
                <p className="mt-1 ">4</p>
              </div>
              <div className="px-2 mt-2 mr-2 text-white ">
                Respondent Selection
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
