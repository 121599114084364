import { useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Table, Typography, Tooltip, Select, Space, Tag } from "antd";
const { Option } = Select;

function SurveySummaryResponses({ SurveyStore, responseData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [countryFilters, setCountryFilters] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [payerTypeFilters, setPayerTypeFilters] = useState([]);
  const [payerRoleFilters, setPayerRoleFilters] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("none");
  const [selectedPayerType, setSelectedPayerType] = useState("none");
  const [selectedPayerRole, setSelectedPayerRole] = useState("none");
  const storeData = SurveyStore.returnData();

  useEffect(() => {
    setFilteredData(responseData);
    let tempColumns = [
      {
        title: "Participant",
        width: 200,
        dataIndex: "participant",
        key: "participant",
        fixed: "left",
      },
      {
        title: "Status",
        width: 80,
        dataIndex: "status",
        key: "status",
        fixed: "left",
        render: (status) => (
          <>
            {status === "Completed" ? (
              <Tag color={"green"} style={{ fontSize: "0.8rem" }}>
                {status}
              </Tag>
            ) : (
              <Tag color={"red"} style={{ fontSize: "0.8rem" }}>
                {"Declined"}
              </Tag>
            )}
          </>
        ),
      },
    ];
    for (let i = 0; i < storeData.length; i++) {
      //define each column with its properties for the table
      let tempColumn = {};
      tempColumn["title"] = (
        <Tooltip title={storeData[i]["quesText"]}>
          <Typography.Text ellipsis={true} style={{ color: "whitesmoke" }}>
            {String(i + 1) + ". " + storeData[i]["quesText"]}
          </Typography.Text>
        </Tooltip>
      );
      tempColumn["width"] = 200;
      tempColumn["align"] = "center";
      tempColumn["dataIndex"] = storeData[i]["quesID"];
      tempColumn["key"] = storeData[i]["quesID"];
      tempColumn["ellipsis"] = true;
      tempColumn["render"] = (text) => {
        return (
          <Tooltip
            arrowPointAtCenter={true}
            overlayStyle={{ fontSize: "0.8rem" }}
            title={text}
          >
            <p className="font-normal mb-0 mr-0">{text}</p>
          </Tooltip>
        );
      };
      tempColumns.push(tempColumn);
    }
    setColumns(tempColumns);
    //get split filters, payer type, payer role, country
    for (let i = 0; i < responseData.length; i++) {
      if (Object.keys(responseData[i]).length < 2) responseData.splice(i, 1);
    }
    let tempCountryFilters = [];
    for (let i = 0; i < responseData.length; i++) {
      tempCountryFilters.push(responseData[i]["participant"].split(", ")[0]);
    }
    let tempPayerTypeFilters = [];
    for (let i = 0; i < responseData.length; i++) {
      tempPayerTypeFilters.push(responseData[i]["participant"].split(", ")[1]);
    }
    let tempPayerRoleFilters = [];
    for (let i = 0; i < responseData.length; i++) {
      tempPayerRoleFilters.push(
        responseData[i]["participant"].split(", ")[2].split(" - ")[0]
      );
    }
    setCountryFilters([...new Set(tempCountryFilters)]);
    setPayerTypeFilters([...new Set(tempPayerTypeFilters)]);
    setPayerRoleFilters([...new Set(tempPayerRoleFilters)]);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [columns]);

  function handleCountryChange(value) {
    //update table data to split by country
    let temp = [];
    setSelectedCountry(value);

    if (value === "none") {
      //update type and role filter values
      temp = [];
      let tempPayerTypeFilters = [];
      for (let i = 0; i < responseData.length; i++) {
        tempPayerTypeFilters.push(
          responseData[i]["participant"].split(", ")[1]
        );
      }
      let tempPayerRoleFilters = [];
      for (let i = 0; i < responseData.length; i++) {
        tempPayerRoleFilters.push(
          responseData[i]["participant"].split(", ")[2].split(" - ")[0]
        );
      }
      setPayerTypeFilters([...new Set(tempPayerTypeFilters)]);
      setPayerRoleFilters([...new Set(tempPayerRoleFilters)]);

      //check whether other splits are applied or not
      if (selectedPayerType !== "none") {
        if (selectedPayerRole !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[1] ===
                selectedPayerType &&
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
                selectedPayerRole
            )
              temp.push(responseData[i]);
          }
        } else {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[1] ===
              selectedPayerType
            )
              temp.push(responseData[i]);
          }
        }
      } else {
        if (selectedPayerRole !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
              selectedPayerRole
            )
              temp.push(responseData[i]);
          }
        } else {
          temp = [...responseData];
        }
      }
    } else {
      //update type and role filter values
      let tempPayerType = [];
      let tempPayerRole = [];
      for (let i = 0; i < responseData.length; i++) {
        if (responseData[i]["participant"].split(", ")[0] === value) {
          tempPayerRole.push(
            responseData[i]["participant"].split(", ")[2].split(" - ")[0]
          );
          tempPayerType.push(responseData[i]["participant"].split(", ")[1]);
        }
      }
      setPayerRoleFilters([...new Set(tempPayerRole)]);
      setPayerTypeFilters([...new Set(tempPayerType)]);

      //check whether other splits are applied or not
      if (selectedPayerType !== "none") {
        if (selectedPayerRole !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[0] === value &&
              responseData[i]["participant"].split(", ")[1] ===
                selectedPayerType &&
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
                selectedPayerRole
            )
              temp.push(responseData[i]);
          }
        } else {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[0] === value &&
              responseData[i]["participant"].split(", ")[1] ===
                selectedPayerType
            )
              temp.push(responseData[i]);
          }
        }
      } else {
        if (selectedPayerRole !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[0] === value &&
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
                selectedPayerRole
            )
              temp.push(responseData[i]);
          }
        } else {
          for (let i = 0; i < responseData.length; i++) {
            if (responseData[i]["participant"].split(", ")[0] === value)
              temp.push(responseData[i]);
          }
        }
      }
    }
    setFilteredData([...temp]);
  }

  function handlePayerTypeChange(value) {
    //update table data to split by payer type
    setSelectedPayerType(value);
    let temp = [];

    if (value === "none") {
      //update country and role filter values
      temp = [];
      let tempCountryFilters = [];
      for (let i = 0; i < responseData.length; i++) {
        tempCountryFilters.push(responseData[i]["participant"].split(", ")[0]);
      }
      let tempPayerRoleFilters = [];
      for (let i = 0; i < responseData.length; i++) {
        tempPayerRoleFilters.push(
          responseData[i]["participant"].split(", ")[2].split(" - ")[0]
        );
      }
      setCountryFilters([...new Set(tempCountryFilters)]);
      setPayerRoleFilters([...new Set(tempPayerRoleFilters)]);

      //check whether other splits are applied or not
      if (selectedCountry !== "none") {
        if (selectedPayerRole !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[0] ===
                selectedCountry &&
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
                selectedPayerRole
            )
              temp.push(responseData[i]);
          }
        } else {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[0] === selectedCountry
            )
              temp.push(responseData[i]);
          }
        }
      } else {
        if (selectedPayerRole !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
              selectedPayerRole
            )
              temp.push(responseData[i]);
          }
        } else {
          temp = [...responseData];
        }
      }
    } else {
      //update role and country filter values
      let tempCountry = [];
      let tempPayerRole = [];
      for (let i = 0; i < responseData.length; i++) {
        if (responseData[i]["participant"].split(", ")[1] === value) {
          tempPayerRole.push(
            responseData[i]["participant"].split(", ")[2].split(" - ")[0]
          );
          tempCountry.push(responseData[i]["participant"].split(", ")[0]);
        }
      }
      setPayerRoleFilters([...new Set(tempPayerRole)]);
      setCountryFilters([...new Set(tempCountry)]);

      //check whether other splits are applied or not
      if (selectedCountry !== "none") {
        if (selectedPayerRole !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[1] === value &&
              responseData[i]["participant"].split(", ")[0] ===
                selectedCountry &&
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
                selectedPayerRole
            )
              temp.push(responseData[i]);
          }
        } else {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[1] === value &&
              responseData[i]["participant"].split(", ")[0] === selectedCountry
            )
              temp.push(responseData[i]);
          }
        }
      } else {
        if (selectedPayerRole !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[1] === value &&
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
                selectedPayerRole
            )
              temp.push(responseData[i]);
          }
        } else {
          for (let i = 0; i < responseData.length; i++) {
            if (responseData[i]["participant"].split(", ")[1] === value)
              temp.push(responseData[i]);
          }
        }
      }
    }
    setFilteredData([...temp]);
  }

  function handlePayerRoleChange(value) {
    //update table data to split by payer role
    setSelectedPayerRole(value);
    let temp = [];

    if (value === "none") {
      //update country and type filter values
      temp = [];
      let tempCountryFilters = [];
      for (let i = 0; i < responseData.length; i++) {
        tempCountryFilters.push(responseData[i]["participant"].split(", ")[0]);
      }
      let tempPayerTypeFilters = [];
      for (let i = 0; i < responseData.length; i++) {
        tempPayerTypeFilters.push(
          responseData[i]["participant"].split(", ")[1]
        );
      }
      setCountryFilters([...new Set(tempCountryFilters)]);
      setPayerTypeFilters([...new Set(tempPayerTypeFilters)]);

      //check whether other splits are applied or not
      if (selectedCountry !== "none") {
        if (selectedPayerType !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[0] ===
                selectedCountry &&
              responseData[i]["participant"].split(", ")[1] ===
                selectedPayerType
            )
              temp.push(responseData[i]);
          }
        } else {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[0] === selectedCountry
            )
              temp.push(responseData[i]);
          }
        }
      } else {
        if (selectedPayerType !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[1] ===
              selectedPayerType
            )
              temp.push(responseData[i]);
          }
        } else {
          temp = [...responseData];
        }
      }
    } else {
      //update type and country filter values
      let tempCountry = [];
      let tempPayerType = [];
      for (let i = 0; i < responseData.length; i++) {
        if (
          responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
          value
        ) {
          tempPayerType.push(responseData[i]["participant"].split(", ")[1]);
          tempCountry.push(responseData[i]["participant"].split(", ")[0]);
        }
      }
      setPayerTypeFilters([...new Set(tempPayerType)]);
      setCountryFilters([...new Set(tempCountry)]);

      //check whether other splits are applied or not
      if (selectedCountry !== "none") {
        if (selectedPayerType !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
                value &&
              responseData[i]["participant"].split(", ")[0] ===
                selectedCountry &&
              responseData[i]["participant"].split(", ")[1] ===
                selectedPayerType
            )
              temp.push(responseData[i]);
          }
        } else {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
                value &&
              responseData[i]["participant"].split(", ")[0] === selectedCountry
            )
              temp.push(responseData[i]);
          }
        }
      } else {
        if (selectedPayerType !== "none") {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
                value &&
              responseData[i]["participant"].split(", ")[1] ===
                selectedPayerType
            )
              temp.push(responseData[i]);
          }
        } else {
          for (let i = 0; i < responseData.length; i++) {
            if (
              responseData[i]["participant"].split(", ")[2].split(" - ")[0] ===
              value
            )
              temp.push(responseData[i]);
          }
        }
      }
    }
    setFilteredData([...temp]);
  }

  return (
    <div className="px-36 mt-4 pb-2">
      <div>
        {isLoading ? (
          <div
            className="flex justify-center items-center"
            style={{ height: "89.6vh" }}
          >
            <ScaleLoader
              color="teal"
              height={60}
              width={6}
              radius={18}
              margin={2}
            />
          </div>
        ) : (
          <div>
            <div className="flex justify-end mb-3 mt-0">
              <Space>
                <Select
                  value={selectedCountry}
                  style={{ width: "12rem" }}
                  onChange={handleCountryChange}
                >
                  <Option value="none">Select Country</Option>
                  {countryFilters.map((item) => {
                    return <Option value={item}>{item}</Option>;
                  })}
                </Select>
                <Select
                  defaultValue="none"
                  style={{ width: "12rem" }}
                  onChange={handlePayerTypeChange}
                >
                  <Option value="none">Select Payer Type</Option>
                  {payerTypeFilters.map((item) => {
                    return <Option value={item}>{item}</Option>;
                  })}
                </Select>
                <Select
                  defaultValue="none"
                  style={{ width: "12rem" }}
                  onChange={handlePayerRoleChange}
                >
                  <Option value="none">Select Payer Expertise</Option>
                  {payerRoleFilters.map((item) => {
                    return <Option value={item}>{item}</Option>;
                  })}
                </Select>
              </Space>
            </div>
            <Table
              pagination={false}
              columns={columns}
              dataSource={filteredData}
              scroll={{ x: 1500, y: 385 }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SurveySummaryResponses;
