import { Button, Popover } from "antd";

const QUESTYPE = {
  1: "Open-Ended",
  2: "Rating",
  3: "Single Select + Open-Ended",
  4: "Ranking",
  5: "Single Select",
  6: "Multi Select",
  7: "Multi Select + Open-Ended",
};

export default function CustomLeftPanel({ title, quesSet, id, addCard }) {
  return (
    <div className="my-1">
      <h6 className="font-bold">{title}</h6>
      <hr />
      <div className="">
        {quesSet.map((item, index) => {
          // Create the options to render on hovering over a question
          let options = (
            <div>
              {item.quesType === 1 ? (
                <p className="text-xs">Open Ended Response</p>
              ) : item.quesType === 2 ? (
                <p className="text-xs">Rating Based Response</p>
              ) : (
                Object.keys(item.responseOptions).map((option, i) => {
                  return (
                    <p className="text-xs">
                      {option}. {item.responseOptions[option]}
                    </p>
                  );
                })
              )}
            </div>
          );
          // create copies of question info to pass, instead of passing directly to avoid 'pass by reference'
          let tempquesText = JSON.parse(JSON.stringify(item.quesText));
          let tempquesType = JSON.parse(JSON.stringify(item.quesType));
          let tempresponseOptions = JSON.parse(
            JSON.stringify(item.responseOptions)
          );
          let tempquesID = JSON.parse(JSON.stringify(item.quesID));
          let tempquesTypeName = JSON.parse(JSON.stringify(item.quesTypeName));
          let tempcustomResponses = JSON.parse(
            JSON.stringify(item.customResponses)
          );
          let tempisUserCreated = JSON.parse(
            JSON.stringify(item.isUserCreated)
          );
          let tempResponseLimit = JSON.parse(
            JSON.stringify(item.responseLimit)
          );
          return (
            <div className="flex" style={{ paddingTop: "0.2rem" }}>
              <div className="col-1 mr-2" key={item.quesID}>
                <Button
                  className="btn-sml"
                  size={"small"}
                  onClick={() => {
                    addCard(
                      tempquesText,
                      tempquesType,
                      tempresponseOptions,
                      tempquesID,
                      tempquesTypeName,
                      tempcustomResponses,
                      tempisUserCreated,
                      tempResponseLimit
                    );
                  }}
                >
                  <i className="fa fa-plus fa-sm" aria-hidden="true"></i>
                </Button>
              </div>
              <div className="col-11">
                <Popover content={options} title="Available Choices">
                  <p key={item.quesID} className="text-sm mb-2">
                    <p className="mb-0.5 text-xs" style={{ fontWeight: 650 }}>
                      {QUESTYPE[item.quesType]}
                    </p>
                    {item.quesText}
                  </p>
                </Popover>
              </div>
            </div>
          );
        })}
      </div>
      <hr />
    </div>
  );
}
