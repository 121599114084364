import Stepper from "../../component/Stepper";
import "./CreateSurveyFields.css";
import {
  Form,
  Input,
  Button,
  Space,
  Card,
  Modal,
  message,
  Select,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { APP_CONSTANTS } from "../../Utils/Constant";
import ScaleLoader from "react-spinners/ScaleLoader";
import { InfoCircleOutlined } from "@ant-design/icons";

const getQuestionData = async (clientId, surveyId) => {
  console.log(surveyId);
  let ResultArray = [];
  //API to get questions of a particular survey
  await axios
    .get(
      APP_CONSTANTS.API_URL +
        "/EachSurveyInfo?ClientId=" +
        clientId +
        "&SurveyId=" +
        surveyId
    )
    .then((res) => {
      console.log(res);
      const result = res.data.result;
      ResultArray = result;
      console.log("survey name", result[0]["surveyName"]);
      console.log("Result: from api", result);
    })
    .catch((err) => {
      console.log({ err });
    });
  return ResultArray;
};

const { Option } = Select;

function CreateSurveyFields({ CurrentSurveyStore, SurveyStore }) {
  const [button, setButton] = useState("Survey Questions");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDrafted, setIsDrafted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const SurveyData = SurveyStore.returnData()[0];

  useEffect(() => {
    // if page is refreshed navigate to homepage
    if (SurveyStore.getNavigationCounter() === 0) {
      navigate("/");
    }
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    //handle ok for cancel modal
    setIsLoading(true);
    setIsModalVisible(false);
    // get survey store data and then delete it
    const storeData = SurveyStore.returnData();
    SurveyStore.deleteStoreData();
    if (!SurveyStore.getIsEdited()) {
      // if not edited
      if (isDrafted) {
        // if drafted, then delete from DB as well
        // axios
        //   .delete(
        //     APP_CONSTANTS.API_URL +
        //       "/DeleteSurvey?ClientId=" +
        //       storeData[0]["clientID"] +
        //       "&SurveyId=" +
        //       storeData[0]["surveyID"]
        //   )
        //   .then((res) => {
        //     if (res["status"] === 200) {
        //       console.log(
        //         "Deleted success >> Survey ID:",
        //         storeData[0]["surveyID"]
        //       );
        //       CurrentSurveyStore.deleteSurvey(storeData[0]["surveyID"]);
        //     } else {
        //       console.log("error deleting survey");
        //     }
        //   })
        //   .catch((error) => {
        //     message.error("Error");
        //     console.log(error);
        //   })
        //   .then((res) => {
        //     setIsLoading(false);
        //     navigate("/currentsurveys");
        //   });
        navigate("/currentsurveys");

        setIsLoading(true);
      } else {
        navigate("/currentsurveys");
        setIsLoading(true);
      }
      setIsDrafted(false);
    } else {
      navigate("/currentsurveys");
      setIsLoading(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const currentsurveys = CurrentSurveyStore.getCurrentSurveyData();
    let check = true;
    values["status"] = "";
    if (SurveyStore.getTeamName() === null) {
      values["team_name"] = sessionStorage.getItem("appInfo")["team_name"];
    } else {
      values["team_name"] = SurveyStore.getTeamName();
    }
    console.log(
      values,
      SurveyStore.getTeamName(),
      window.sessionStorage.getItem("appInfo")["team_name"]
    );
    // check for duplicacy of survey name except when survey is being edited
    SurveyStore.addSurveyFieldsData(values);
    if (!SurveyStore.getIsEdited() && currentsurveys != null) {
      for (let i = 0; i < currentsurveys.length; i++) {
        if (currentsurveys[i]["surveyName"] === values["surveyName"]) {
          console.log("Found Duplicate Survey Name");
          check = false;
          break;
        }
      }
    }
    if (SurveyStore.getIsEdited() && currentsurveys != null) {
      for (let i = 0; i < currentsurveys.length; i++) {
        if (
          values["surveyName"] !== SurveyStore.returnData()[0]["surveyName"] &&
          currentsurveys[i]["surveyName"] === values["surveyName"]
        ) {
          console.log("Found Duplicate Survey Name");
          check = false;
          break;
        }
      }
    }
    if (check === false) {
      message.error("Please provide unique Survey Name");
      setIsLoading(false);
      SurveyStore.deleteStoreData();
    } else {
      //check which button is clicked to determine whether to proceed to next screen or not
      if (button === "Survey Questions") {
        SurveyStore.setIsEdited(true);
        const storeData = SurveyStore.returnData();
        // Add data to store and DB and then navigate
        SurveyStore.addSurveyFieldsData(values);
        const toDBData = SurveyStore.returnData();
        console.log("toDB from surveyQuestions", toDBData);
        message.loading("Saving!", [1]);
        //API to save the survey details
        //returns a surveyID alloted to this survey
        axios
          .post(
            APP_CONSTANTS.API_URL +
              "/CreateNewSurvey?ClientId=" +
              toDBData[0]["clientID"] +
              "&lastSavedStep=2",
            toDBData
          )
          .then((response) => {
            //update the survey id in storeData
            console.log("Survey ID from DB", response.data.result[0]);
            values["surveyID"] = response.data.result[0];
            values["clientID"] = toDBData[0]["clientID"];
            console.log("Data sent to DB successfully");
            CurrentSurveyStore.addToCurrentSurveysFromSurveyFields(values);
            for (let i = 0; i < toDBData.length; i++) {
              toDBData[i]["surveyID"] = response.data.result[0];
            }
            SurveyStore.addSurveyFieldsData(toDBData[0]);
            setIsLoading(false);
            console.log("response", response);
            if (response.data.status === 200) {
              message.success("Saved!");
              SurveyStore.increaseNavigationCounter();
              navigate("/createsurveyquestions");
            } else message.error("Error!");
          })
          .catch((err) => {
            message.error("Error");
            console.log(err);
            setIsLoading(false);
          });
      } else {
        // Add data to store and DB but dont navigate
        SurveyStore.setIsEdited(true);
        const storeData = SurveyStore.returnData();
        SurveyStore.addSurveyFieldsData(values);
        const toDBData = SurveyStore.returnData();
        console.log("toDB", toDBData);
        message.loading("Saving!", [1]);
        //API to save the survey details
        //returns a surveyID alloted to this survey
        axios
          .post(
            APP_CONSTANTS.API_URL +
              "/CreateNewSurvey?ClientId=" +
              toDBData[0]["clientID"] +
              "&lastSavedStep=1",
            toDBData
          )
          .then((response) => {
            //update the survey id in storeData
            console.log("Survey ID from DB", response.data.result[0]);
            values["surveyID"] = response.data.result[0];
            values["clientID"] = toDBData[0]["clientID"];
            console.log("Data sent to DB successfully");
            CurrentSurveyStore.addToCurrentSurveysFromSurveyFields(values);
            for (let i = 0; i < toDBData.length; i++) {
              toDBData[i]["surveyID"] = response.data.result[0];
            }
            SurveyStore.addSurveyFieldsData(toDBData[0]);
            setIsLoading(false);

            if (response.data.status === 200) message.success("Saved!");
            else message.error("Error!");
          })
          .catch((err) => {
            message.error("Error");
            console.log(err);
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "87.5vh" }}
        >
          <ScaleLoader
            color="teal"
            height={60}
            width={6}
            radius={18}
            margin={2}
          />
        </div>
      ) : (
        <div
          className="pb-2"
          style={{ minHeight: "86.8vh", overflowY: "auto" }}
        >
          <Stepper current={0} />
          <div
            className="mt-3 mb-4 flex items-center justify-center"
            style={{ textAlign: "center" }}
          >
            <div className="text-xl ">
              {/* <b className="" style={{ color: "darkslategrey" }}>
                Survey Fields
              </b> */}
            </div>
            <div className="pl-8 w-1/2 flex items-center justify-center">
              <p className="mb-0 text-blue-400">
                Fields noted by{" "}
                <InfoCircleOutlined
                  style={{
                    fontSize: "0.7rem",
                    color: "blueviolet",
                    verticalAlign: "0.06cm",
                  }}
                />{" "}
                will be shared with Payer experts as part of their invitation to
                participate in the survey. Please confirm that it does not
                contain any sensitive information.
              </p>
            </div>
          </div>
          <div>
            <Form
              name="surveyfieldsform"
              labelCol={{ span: 8 }}
              onFinish={onFinish}
              initialValues={{ remember: true }}
              wrapperCol={{ span: 13 }}
              layout="horizontal"
            >
              <div className="pl-52 pr-60">
                <Form.Item
                  label="Survey Name"
                  name={"surveyName"}
                  id="surveyname"
                  initialValue={SurveyData["surveyName"]}
                  rules={[
                    { required: true, message: "Please Enter Survey Name!" },
                  ]}
                  style={{ color: "darkslategrey" }}
                >
                  <Input placeholder="Enter Survey Name" />
                </Form.Item>
                <Form.Item
                  label="Asset (if applicable)"
                  name={"surveyAsset"}
                  initialValue={SurveyData["surveyAsset"]}
                >
                  <Input placeholder="Enter Asset" />
                </Form.Item>
                <Form.Item
                  label={
                    <div className="flex">
                      <div className="flex justify-center items-center">
                        <InfoCircleOutlined
                          style={{ fontSize: "0.7rem", color: "blueviolet" }}
                        />
                      </div>
                      <p className="mb-0 ml-1">
                        Therapy Area/Indication (if applicable)
                      </p>
                    </div>
                  }
                  initialValue={SurveyData["therapyArea"]}
                  name={"therapyArea"}
                >
                  <Input placeholder="Enter Therapy Area/ Indication" />
                </Form.Item>
                <Form.Item
                  label={
                    <div className="flex">
                      <div className="flex justify-center items-center">
                        <InfoCircleOutlined
                          style={{ fontSize: "0.7rem", color: "blueviolet" }}
                        />
                      </div>
                      <p className="mb-0 ml-1">Nature of Research</p>
                    </div>
                  }
                  initialValue={SurveyData["researchType"]}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Nature of Research!",
                    },
                  ]}
                  name={"researchType"}
                >
                  <Input placeholder="Examples: Market dynamics, pricing, burden of disease, TPP testing, trial design..." />
                </Form.Item>
                <Form.Item
                  name={"surveyDescription"}
                  initialValue={SurveyData["surveyDescription"]}
                  label={
                    <div className="flex">
                      <div className="flex justify-center items-center">
                        <InfoCircleOutlined
                          style={{ fontSize: "0.7rem", color: "blueviolet" }}
                        />
                      </div>
                      <p className="mb-0 ml-1">Research Objectives</p>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Research Objectives!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          getFieldValue("surveyDescription").replace(
                            / /g,
                            ""
                          ) === ""
                        ) {
                          return Promise.reject(
                            "Please provide atleast one non-whitespace character"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.TextArea
                    rows={6}
                    placeholder="Examples: ‘The objective of this survey is to understand the perceived value drivers for a hypothetical drug in development for the treatment of breast cancer’; ‘The goal of the research is to confirm the current level of unmet need in local markets’; ‘This study will help identify case studies / best practice examples for x…’"
                  />
                </Form.Item>
              </div>
              <div
                style={{ marginTop: "4rem", paddingTop: "0.5rem" }}
                id="footer"
              >
                <Card size="small">
                  <Space>
                    <Tooltip
                      title={`Proceeding to "Survey Questions" will automatically save your current progress`}
                    >
                      <Button
                        onClick={() => setButton("Survey Questions")}
                        type="primary"
                        htmlType="submit"
                      >
                        Survey Questions
                      </Button>
                    </Tooltip>
                    <Button onClick={() => setButton("Save")} htmlType="submit">
                      Save as Draft
                    </Button>
                    <Button type="danger" onClick={showModal}>
                      Cancel
                    </Button>
                  </Space>
                </Card>
              </div>
            </Form>
          </div>
          <Modal
            title="Cancel Survey"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            {/* {!SurveyStore.getIsEdited() && !isDrafted ? (
              <p>Do you want to delete this survey and all its related data?</p>
            ) : (
              <p>Are you sure? Changes made won't be applied!</p>
            )} */}
            <p>Are you sure? Changes made won't be applied!</p>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default observer(CreateSurveyFields);
