import { Menu } from "antd";
import { useState } from "react";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import faqClient from "../FaqClient.json";
import faqPayer from "../FaqPayer.json";
import keyInformation from "../KeyInformation.json";

const { Panel } = Collapse;

function FAQ({ onPayerScreen }) {
  const [keySelected, setKeySelected] = useState("keypoints");
  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  function handleMenuClick(e) {
    setKeySelected(e.key);
  }
  return (
    <div className="pt-0">
      {onPayerScreen ? (
        // if on payer screen, display payers' FAQ
        <div className="mt-2 scrollableFAQ">
          <Collapse
            accordion
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {faqPayer["faq"].map((item, i) => {
              return (
                <Panel header={item["question"]} key={i}>
                  <p>{item["answer"]}</p>
                </Panel>
              );
            })}
          </Collapse>
        </div>
      ) : (
        // if on payer screen, display client's FAQ
        <div>
          <Menu
            mode="horizontal"
            onClick={handleMenuClick}
            defaultSelectedKeys={["keypoints"]}
          >
            <Menu.Item key="keypoints">Key Information</Menu.Item>

            <Menu.Item key="faq">FAQ </Menu.Item>
          </Menu>
          <div>
            {keySelected === "keypoints" ? (
              //toggle between Key Information and FAQ tab
              <div className="pl-5 mt-4">
                {keyInformation["KeyInformation"].map((item, i) => {
                  return (
                    <div key={i}>
                      <p className="mb-2">
                        <b>{i + 1}.&nbsp;</b>
                        {item["text"]}
                      </p>
                      <div>
                        {item["subText"] !== undefined &&
                        item["subText"] !== null &&
                        item["subText"].length !== 0
                          ? item["subText"].map((subText, ind) => {
                              return (
                                <p key={ind} className="mb-1 pl-8">
                                  <b>{alphabets[ind].toLowerCase()}.&nbsp;</b>
                                  {subText}
                                </p>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="mt-2 scrollableFAQ">
                <Collapse
                  accordion
                  expandIconPosition="right"
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                >
                  {faqClient["faq"].map((item, i) => {
                    if(i === 0)
                    {
                      return (
                        <Panel header={item["question"]} key={i}>
                          <div>
                            <p>
                              {item["answer"]["mainheader"]}
                            </p>
                            <div>
                            {item["answer"]["subheader"].map((item1, j) => {
                             return(
                              <div>
                              <p className="font-bold italic">
                                {item1["header"]}
                              </p>
                              <p className="italic">
                                {item1["text"]}
                              </p>
                            </div>
                             )
                             
                            })}
                            </div>
                          </div>
                        </Panel>
                      );
                    }
                    else
                    {
                      return (
                        <Panel header={item["question"]} key={i}>
                          <p>{item["answer"]}</p>
                        </Panel>
                      );
                    }
                  })}
                </Collapse>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default FAQ;
