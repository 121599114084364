import { Select, List, message, Avatar, Skeleton, Divider } from "antd";
import { useState, useEffect } from "react";
import BarChart from "./BarChart";
import CommentList from "./CommentList";
import PieChart from "./PieChart";

const { Option } = Select;
const QUESTYPE = {
  1: "Open-ended",
  2: "Rating",
  3: "Single-choice + open-ended",
  4: "Ranking",
  5: "Single-choice",
  6: "Multi-select",
  7: "Multi-select + open-ended",
};

export default function SummaryQuestionCards({ data, serial, responseData }) {
  const [PanelOpen, setPanelOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [split, setSplit] = useState("country");
  const [processedData, setProcessedData] = useState([]);
  const panelToggle = (event) => {
    setPanelOpen(PanelOpen ? false : true);
  };

  //extract useful data based on question type and convert it to required format
  useEffect(() => {
    if (
      responseData !== undefined &&
      responseData !== null &&
      responseData.length !== 0
    ) {
      if (responseData[0]["quesType"] === 1) {
        let temp = [];
        responseData.map((item, index) => {
          temp.push({
            country: item["payerCountry"],
            comment: item["payerComment"],
            type: item["payerType"],
            role: item["payerRole"],
            payerId: item["payerID"],
          });
        });
        setProcessedData([...temp]);
      } else if (responseData[0]["quesType"] === 2) {
        let temp = [];
        responseData.map((item, index) => {
          temp.push({
            country: item["payerCountry"],
            response: item["payerResponses"],
            type: item["payerType"],
            role: item["payerRole"],
            responseOptions: item["responseOptions"],
            payerId: item["payerID"],
          });
        });
        setProcessedData([...temp]);
      } else if (responseData[0]["quesType"] === 3) {
        let temp = [];
        responseData.map((item, index) => {
          temp.push({
            country: item["payerCountry"],
            response: item["payerResponses"],
            comment: item["payerComment"],
            type: item["payerType"],
            role: item["payerRole"],
            responseOptions: item["responseOptions"],
            payerId: item["payerID"],
          });
        });
        setProcessedData([...temp]);
      } else if (responseData[0]["quesType"] === 5) {
        let temp = [];
        responseData.map((item, index) => {
          temp.push({
            country: item["payerCountry"],
            response: item["payerResponses"],
            type: item["payerType"],
            role: item["payerRole"],
            responseOptions: item["responseOptions"],
            payerId: item["payerID"],
          });
        });
        setProcessedData([...temp]);
      } else if (responseData[0]["quesType"] === 6) {
        let temp = [];
        responseData.map((item, index) => {
          temp.push({
            country: item["payerCountry"],
            response: item["payerResponses"],
            type: item["payerType"],
            role: item["payerRole"],
            responseOptions: item["responseOptions"],
            payerId: item["payerID"],
          });
        });
        setProcessedData([...temp]);
      } else if (responseData[0]["quesType"] === 7) {
        let temp = [];
        responseData.map((item, index) => {
          temp.push({
            country: item["payerCountry"],
            response: item["payerResponses"],
            type: item["payerType"],
            role: item["payerRole"],
            comment: item["payerComment"],
            responseOptions: item["responseOptions"],
            payerId: item["payerID"],
          });
        });
        setProcessedData([...temp]);
      } else if (responseData[0]["quesType"] === 4) {
        let temp = [];
        responseData.map((item, index) => {
          temp.push({
            country: item["payerCountry"],
            response: item["payerResponses"],
            type: item["payerType"],
            role: item["payerRole"],
            responseOptions: item["responseOptions"],
            payerId: item["payerID"],
          });
        });
        setProcessedData([...temp]);
      }
      setLoading(false);
    }
  }, [responseData]);

  return (
    <div>
      {loading ? (
        ""
      ) : (
        <div className="relative rounded-lg border border-1 bg-white border-gray-300 mt-2">
          <div className="pb-1 px-2 rounded-t-lg backgroundQuestion text-black">
            <div className="flex mb-1">
              <div className="w-10/12 flex px-2 pt-4">
                <div
                  style={{ minWidth: "2.25rem" }}
                  className="step-button text-center activeSerialQuestion text-black rounded-full mr-4"
                >
                  <p className="mt-1 font-bold">{serial}</p>
                </div>
                <div className="flex items-center">{data["quesText"]}</div>
              </div>
              <div className="w-2/12 flex justify-end px-2 pt-4">
                {/* <p className="text-xs mb-0 mt-1">{QUESTYPE[data["quesType"]]}</p> */}
                <button
                  style={{ height: "2rem" }}
                  className="py-1 px-2 rounded-md text-xs ml-4 text-gray-600 border-2"
                  onClick={(e) => panelToggle(e.currentTarget)}
                >
                  <i
                    style={{ color: "black" }}
                    className={`fa-solid fa-chevron-${
                      PanelOpen ? "up" : "down"
                    }`}
                  ></i>
                </button>
              </div>
            </div>{" "}
          </div>

          <div
            className={`container pe-0 ${PanelOpen ? "" : "hidden"}`}
            key={serial}
          >
            <hr
              className={`${PanelOpen ? "" : "hidden"}`}
              style={{
                // marginTop: "0.5rem",
                marginInline: "1rem",
                border: "0.02rem solid lightgray",
              }}
            />
            <div className="flex px-4 pt-2 justify-end">
              {responseData[0]["quesType"] === 1 ? (
                ""
              ) : (
                // add split options for remaining question types
                <Select
                  defaultValue="country"
                  style={{ width: "12rem" }}
                  onChange={(value) => {
                    setSplit(value);
                  }}
                >
                  <Option value="none">Consolidated Summary</Option>
                  <Option value="country">Country</Option>
                  <Option value="type">Country- Payer Type</Option>
                  <Option value="role">Country- Payer Expertise</Option>
                </Select>
              )}
            </div>

            <div className="px-4 py-2">
              {responseData[0]["quesType"] === 1 ? (
                <CommentList data={processedData} scroll={true} />
              ) : responseData[0]["quesType"] === 2 ? (
                <div>
                  <BarChart
                    data={processedData}
                    split={split}
                    type={"rating"}
                  />
                </div>
              ) : responseData[0]["quesType"] === 3 ? (
                <div>
                  {split === "none" ? (
                    <div>
                      <PieChart data={processedData} />
                      <p className="text-center font-bold text-md mb-2 mt-4">
                        Comments
                      </p>
                      <CommentList data={processedData} scroll={true} />
                    </div>
                  ) : (
                    <div>
                      <BarChart
                        data={processedData}
                        split={split}
                        type={"single"}
                      />
                      <p className="text-center font-bold text-md mb-2 mt-4">
                        Comments
                      </p>
                      <CommentList data={processedData} scroll={true} />
                    </div>
                  )}
                </div>
              ) : responseData[0]["quesType"] === 5 ? (
                <div>
                  {split === "none" ? (
                    <PieChart data={processedData} />
                  ) : (
                    <BarChart
                      data={processedData}
                      split={split}
                      type={"single"}
                    />
                  )}
                </div>
              ) : responseData[0]["quesType"] === 6 ? (
                <BarChart data={processedData} split={split} type={"multi"} />
              ) : responseData[0]["quesType"] === 7 ? (
                <div>
                  <BarChart data={processedData} split={split} type={"multi"} />
                  <p className="text-center font-bold text-md mb-2 mt-4">
                    Comments
                  </p>
                  <CommentList data={processedData} scroll={true} />
                </div>
              ) : responseData[0]["quesType"] === 4 ? (
                <BarChart data={processedData} split={split} type={"ranking"} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
