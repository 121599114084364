import { Button } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import publishImage from "../../publish.png";
function PublishSurvey({ SurveyStore }) {
  const navigate = useNavigate();

  useEffect(() => {
    // if page is refreshed navigate to homepage
    if (SurveyStore.getNavigationCounter() === 0) {
      navigate("/");
    }
  });

  return (
    <div
      className="flex items-center justify-center"
      style={{ minHeight: "87.5vh" }}
    >
      <div>
        <div style={{ minHeight: "19.5rem" }}>
          <img src={publishImage} alt="Survey Published!"></img>
        </div>
        <p className="font-bold text-xl px-6">
          Your Survey has been sent out successfully!
        </p>
        <div className="mt-4" style={{ paddingInline: "9rem" }}>
          <a
            onClick={() => SurveyStore.deleteStoreData()}
            className="nav-link"
            href="/"
          >
            <Button type="primary">Back to Survey Dashboard</Button>
          </a>
        </div>
      </div>
    </div>
  );
}
export default PublishSurvey;
