import NavBar from "./component/NavBar";
import "antd/dist/antd.css";
import CurrentSurveysTable from "./Screens/CurrentSurvey/CurrentSurveysTable";
import SurveySummary from "./Screens/CurrentSurvey/SurveySummary";
import CreateSurveyQuestions from "./Screens/CreateSurvey/CreateSurveyQuestions";
import CreateSurveyFields from "./Screens/CreateSurvey/CreateSurveyFields";
import { Route, Routes, Navigate } from "react-router-dom";
import SurveyStore from "./Store/SurveyStore";
import QuestionBankStore from "./Store/QuestionBankStore";
import ReviewSurvey from "./Screens/CreateSurvey/ReviewSurvey";
import RespondentSelection from "./Screens/CreateSurvey/RespondentSelection";
import PublishSurvey from "./Screens/CreateSurvey/PublishSurvey";
import ThankYouRejected from "./Screens/PayerScreens/ThankYouRejected";
import ThankYouCompleted from "./Screens/PayerScreens/ThankYouCompleted";
import Login from "./Screens/account/login/Login";
import PayerStatus from "./Screens/PayerScreens/PayerStatus";
import PayerSurvey from "./Screens/PayerScreens/PayerSurvey";
import MainFooter from "./component/MainFooter";
import CurrentSurveyStore from "./Store/CurrentSurveyStore";
import PayerStatusStore from "./Store/PayerStatusStore";
import { useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./App.css";
import { Modal } from "antd";
import ClosedSurvey from "./Screens/PayerScreens/ClosedSurvey";
import Admin from "./Screens/admin/Admin";
import UnAuthUser from "./Screens/admin/UnAuthUser";
// import { useHistory, useLocation } from "react-router-dom";

export default function App() {
  const surveyStore = new SurveyStore(); //maintains survey related data for each survey
  const questionBankStore = new QuestionBankStore(); //maintains the question bank
  const currentSurveyStore = new CurrentSurveyStore(); //maintains the records of surveys created by a user
  const payerStatusStore = new PayerStatusStore(); //maintains survey related data for each payer like payerStatus, payerId, etc
  const [onLoginPage, setOnLoginPage] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [onPayerScreen, setOnPayerScreen] = useState(false);

  useEffect(() => {
    let appInfo = JSON.parse(sessionStorage.getItem("appInfo"));
    console.log(appInfo);
    if (
      String(window.location.pathname).includes("payer") ||
      String(window.location.pathname).includes("thankyou") ||
      String(window.location.pathname).includes("closedsurvey")
    ) {
      setOnPayerScreen(true);
      console.log("On Payer Screen");
    }

    if (appInfo !== null) {
      if (
        appInfo["clientId"] !== null ||
        appInfo["clientId"] !== undefined ||
        appInfo["clientId"] !== 0
      ) {
        surveyStore.setClientId(appInfo["clientId"]);
        surveyStore.setTeamName(appInfo["team_name"]);

        setOnLoginPage(true);
      }
    } else {
      setShowLogin(true);
    }
    setIsLoading(false);
  }, []);
  return (
    <main>
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "89.6vh" }}
        >
          <ScaleLoader
            color="teal"
            height={60}
            width={6}
            radius={18}
            margin={2}
          />
        </div>
      ) : (
        <div>
          {onPayerScreen ? (
            //do not display navbar on payer screens
            ""
          ) : (
            <div>
              {onLoginPage && (
                //display navbar if user is logged in
                <NavBar
                  //passing the required stores and states as props
                  setOnLoginPage={setOnLoginPage}
                  setIsLoading={setIsLoading}
                  SurveyStore={surveyStore}
                />
              )}
            </div>
          )}
          <div style={{ height: "100%", backgroundColor: "whitesmoke" }}>
            {/* We define our routes here */}
            <Routes>
              <Route
                path="/admin"
                //route to admin page
                element={<Admin SurveyStore={surveyStore} />}
              />
              <Route
                path="/"
                //route to login page
                element={
                  <Login
                    //passing the required stores and states as props
                    setOnLoginPage={setOnLoginPage}
                    SurveyStore={surveyStore}
                  />
                }
              />
              <Route
                path="/currentsurveys"
                //route to the dashboard page, where all surveys are displayed
                element={
                  <CurrentSurveysTable
                    //passing the required stores as props
                    CurrentSurveyStore={currentSurveyStore}
                    SurveyStore={surveyStore}
                  />
                }
              />
              <Route
                path="/createsurvey"
                //route to create new survey page, where details like survey name and description are captured
                element={
                  <CreateSurveyFields
                    //passing the required stores as props
                    CurrentSurveyStore={currentSurveyStore}
                    SurveyStore={surveyStore}
                  />
                }
              />
              <Route
                path="/createsurveyquestions"
                //route to survey questions page, where user can add questions to the survey
                element={
                  <CreateSurveyQuestions
                    //passing the required stores as props
                    QuestionBankStore={questionBankStore}
                    SurveyStore={surveyStore}
                    CurrentSurveyStore={currentSurveyStore}
                  />
                }
              />
              <Route
                path="/reviewsurvey"
                //route to review survey page
                element={
                  <ReviewSurvey
                    //passing the required stores as props
                    SurveyStore={surveyStore}
                    CurrentSurveyStore={currentSurveyStore}
                  />
                }
              />
              <Route
                path="/respondentselection"
                //route to respondent selection page, from where payers/respondents can be selected
                element={
                  <RespondentSelection
                    //passing the required stores as props
                    SurveyStore={surveyStore}
                    CurrentSurveyStore={currentSurveyStore}
                  />
                }
              />
              <Route
                path="/publishsurvey"
                //route to the page which is to be displayed when user publishes a survey
                element={
                  <PublishSurvey
                    //passing the required stores as props
                    SurveyStore={surveyStore}
                  />
                }
              />
              <Route
                path="/payerstatus"
                //route to the payer status screen, where the payer updates its status (accept/reject)
                element={
                  <PayerStatus
                    //passing the required stores as props
                    PayerStatusStore={payerStatusStore}
                  />
                }
              />
              <Route
                path="/thankyour"
                //route to thank you rejected screen, to be displayed when payer rejects the survey
                element={
                  <ThankYouRejected
                    //passing the required stores as props
                    PayerStatusStore={payerStatusStore}
                  />
                }
              />
              <Route
                path="/thankyouc"
                //route to thank you completed screen, to be displayed when payer completes the survey
                element={
                  <ThankYouCompleted //passing the required stores as props
                    PayerStatusStore={payerStatusStore}
                  />
                }
              />
              <Route
                path="/closedsurvey"
                //route to closed survey screen, to be displayed when the survey is completed
                element={<ClosedSurvey PayerStatusStore={payerStatusStore} />}
              />
              <Route
                path="/payersurvey"
                //route to payer survey screen, where the survey would be displayed to the payer
                element={
                  <PayerSurvey
                    //passing the required stores as props
                    PayerStatusStore={payerStatusStore}
                  />
                }
              />
              <Route
                path="/surveysummary"
                //route to survey summary screen, which contains the charts and responses for a survey
                element={
                  <SurveySummary
                    //passing the required stores as props
                    CurrentSurveyStore={currentSurveyStore}
                    SurveyStore={surveyStore}
                  />
                }
              />
              <Route
                path="/invalid"
                //route to unauthorise access to admin page
                element={<UnAuthUser SurveyStore={surveyStore} />}
              />
              <Route
                path="*"
                //route to login page is url is not among the defined routes
                element={
                  <Login
                    setOnLoginPage={setOnLoginPage}
                    SurveyStore={surveyStore}
                  />
                }
              />
            </Routes>
          </div>
          {onPayerScreen ? "" : <div>{onLoginPage && <MainFooter />}</div>}
        </div>
      )}
    </main>
  );
}
