import { Tooltip } from "antd";
import ZSLogo from "../zslogo.png";
import { Modal, Button } from "antd";
import React, { useState } from "react";
import payerconnect from "../payerconnect.png";
import FAQ from "./FAQ";

export default function NavBar() {
  const [isModalVisible, setIsModalVisible] = useState(false); //state to manage faq modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div className="bg-black flex sticky top-0 z-50">
      <div className="ml-5 w-96 flex items-center">
        <div className="w-1/8 mr-2 my-1.5">
          <img
            src={ZSLogo}
            alt="ZS"
            style={{
              width: "2.2rem",
              padding: "0.3rem",
              marginLeft: "0.2rem",
            }}
          />
        </div>
        <div className="flex mr-2 text-2xl text-white ">
          <p className="text-white mb-0">ZS&nbsp;</p>
          <b>Payer&nbsp;Connect</b>
          <p
            className="font-bold ml-1"
            style={{
              verticalAlign: "super",
              fontSize: "0.6rem",
              marginTop: "-0.3rem",
            }}
          >
            {" "}
            TM
          </p>
        </div>
        <div className="w-1/6 my-1.5">
          <img
            src={payerconnect}
            alt="payerconnect"
            style={{
              width: "3.2rem",
              height: "2.7rem",
              // padding: "0.3rem",
              marginLeft: "0.2rem",
            }}
          />
        </div>
      </div>
      <div className="flex w-4/5 justify-end py-5 mr-5">
        {/* <a className="nav-link" href="/currentsurveys"> */}
        <Tooltip title="Help">
          <i
            className="fa fa-circle-question mx-2"
            style={{
              color: "whitesmoke",
              cursor: "pointer",
              fontSize: "1.5rem",
            }}
            aria-hidden="true"
            onClick={showModal}
          ></i>
        </Tooltip>
        {/* </a> */}
      </div>
      <Modal
        title="Help"
        style={{ minWidth: "60rem" }}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <FAQ onPayerScreen={true} />
      </Modal>
    </div>
  );
}
