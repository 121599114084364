import { Layout } from "antd";
import { EmailOutline } from "@styled-icons/evaicons-outline/EmailOutline";
import { CopyrightOutlined } from "@ant-design/icons";
import FAQ_Terms from "./FAQ_Terms";
import { Modal, Button } from "antd";
import React, { useState } from "react";
const { Footer } = Layout;

function MainFooter() {
  const [isModalVisible, setIsModalVisible] = useState(false); //states to manage faq modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <Footer
      style={{
        textAlign: "center",
        padding: "0px 20px 0px 20px",
        width: "100%",
        backgroundColor: "black",
        height: "1.8rem",
        position: "relative",
      }}
    >
      <span className="text-white" style={{ float: "left" }}
      aria-hidden="true"
      onClick={showModal}>
       <a> Terms of Use </a>
      </span>
      <span
        className="text-white"
        style={{ float: "left", marginLeft: "30px" }}
      >
        Contact :{" "}
        <a href="mailto:payerconnect@zs.com">
          <EmailOutline size={18} />
          payerconnect@zs.com
        </a>
      </span>
      <span className="text-white flex" style={{ float: "right" }}>
        <CopyrightOutlined style={{ marginTop: "0.3rem" }} />
        <p className="mb-0 ml-2">2022 ZS All Rights Reserved</p>
      </span>
      <Modal
        style={{ minWidth: "60rem",marginTop: "-2.3rem"}}
        title="Terms of Use"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <FAQ_Terms/>
      </Modal>
    </Footer>
  );
}

export default MainFooter;
