import { useState } from "react";
import ReactExport from "react-data-export";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import { APP_CONSTANTS } from "../../Utils/Constant";
import { Button, message } from "antd";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

//API to fetch data to be exported in Monthly Report in .xlsx
const getMonthEndReportData = async (start, end) => {
  let ResultArray = [];
  await axios
    .get(
      APP_CONSTANTS.API_URL +
        "/MonthlyPayerReport?start=" +
        start +
        "&end=" +
        end +
        "&team_name=" +
        JSON.parse(sessionStorage.getItem("appInfo"))["team_name"]
    )
    .then((res) => {
      console.log("Response: from api", res);
      const result = res.data.result;
      ResultArray = result;
    })
    .catch((err) => {
      console.log({ err });
    });
  return ResultArray;
};

function MonthEndPayerReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  //handle date filter
  function onChange(value, dateString) {
    if (dateString[0] === "") {
      setStartDate("");
      setEndDate("");
      setReportData([]);
      setDisabled(true);
      return;
    }
    setReportData([]);
    setDisabled(true);
    let startDate = dateString[0];
    let endDate = new Date(dateString[1]);
    endDate.setDate(endDate.getDate() + 1);
    endDate = endDate.toISOString().split("T")[0];
    setStartDate(startDate);
    setEndDate(endDate);
    getMonthEndReportData(startDate, endDate).then((output) => {
      if (output === null || output === undefined || output.length === 0) {
        message.error("Error in fetching report!");
        return;
      }
      setReportData(output);
      setDisabled(false);
    });
  }

  function DownloadButton() {
    //the download button and icon to be displayed on screen
    return (
      <div className="flex items-center justify-center">
        <Button
          disabled={disabled}
          type="primary"
          onClick={() => message.loading("Downloading", [0.8])}
        >
          Download Report
        </Button>
      </div>
    );
  }

  function ExportCSV() {
    //monthly report in excel format
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    //add columns of documents in columns attribute
    //add responses in data attribute
    debugger;
    const multiDataSet = [
      {
        columns: [
          {
            title: "Payer Name",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Payer Country",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Payer Type",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Payer Expertise",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Payer E-Mail Address",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Rate/Question",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Adds Tax?",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Surveys Accepted",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Questions Answered",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Team Name/Project Code",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
        ],
        data: [],
      },
    ];

    for (let i = 0; i < reportData.length; i++) {
      if (Number(reportData[i]["acceptedSurveys"]) === 0) continue;
      let temp = [];
      temp.push({
        value: reportData[i]["payer_name"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: reportData[i]["payerCountry"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: reportData[i]["payerType"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: reportData[i]["payerRole"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: reportData[i]["payerMail"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: reportData[i]["question_Rate"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: reportData[i]["tax_Information"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: reportData[i]["acceptedSurveys"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: reportData[i]["questionsAnswered"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: JSON.parse(sessionStorage.getItem("appInfo"))["team_name"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      multiDataSet[0].data.push(temp);
    }

    if (multiDataSet[0]["data"].length === 0) {
      // setDisabled(true);
      multiDataSet[0].data.push([
        {
          value: "NA",
          style: {
            border: {
              bottom: { style: "medium", color: { rgb: "00000000" } },
              top: { style: "medium", color: { rgb: "00000000" } },
              left: { style: "medium", color: { rgb: "00000000" } },
              right: { style: "medium", color: { rgb: "00000000" } },
            },
          },
        },
        {
          value: "NA",
          style: {
            border: {
              bottom: { style: "medium", color: { rgb: "00000000" } },
              top: { style: "medium", color: { rgb: "00000000" } },
              left: { style: "medium", color: { rgb: "00000000" } },
              right: { style: "medium", color: { rgb: "00000000" } },
            },
          },
        },
        {
          value: "NA",
          style: {
            border: {
              bottom: { style: "medium", color: { rgb: "00000000" } },
              top: { style: "medium", color: { rgb: "00000000" } },
              left: { style: "medium", color: { rgb: "00000000" } },
              right: { style: "medium", color: { rgb: "00000000" } },
            },
          },
        },
        {
          value: "NA",
          style: {
            border: {
              bottom: { style: "medium", color: { rgb: "00000000" } },
              top: { style: "medium", color: { rgb: "00000000" } },
              left: { style: "medium", color: { rgb: "00000000" } },
              right: { style: "medium", color: { rgb: "00000000" } },
            },
          },
        },
        {
          value: "NA",
          style: {
            border: {
              bottom: { style: "medium", color: { rgb: "00000000" } },
              top: { style: "medium", color: { rgb: "00000000" } },
              left: { style: "medium", color: { rgb: "00000000" } },
              right: { style: "medium", color: { rgb: "00000000" } },
            },
          },
        },
        {
          value: "NA",
          style: {
            border: {
              bottom: { style: "medium", color: { rgb: "00000000" } },
              top: { style: "medium", color: { rgb: "00000000" } },
              left: { style: "medium", color: { rgb: "00000000" } },
              right: { style: "medium", color: { rgb: "00000000" } },
            },
          },
        },
        {
          value: "NA",
          style: {
            border: {
              bottom: { style: "medium", color: { rgb: "00000000" } },
              top: { style: "medium", color: { rgb: "00000000" } },
              left: { style: "medium", color: { rgb: "00000000" } },
              right: { style: "medium", color: { rgb: "00000000" } },
            },
          },
        },
        {
          value: "NA",
          style: {
            border: {
              bottom: { style: "medium", color: { rgb: "00000000" } },
              top: { style: "medium", color: { rgb: "00000000" } },
              left: { style: "medium", color: { rgb: "00000000" } },
              right: { style: "medium", color: { rgb: "00000000" } },
            },
          },
        },
        {
          value: "NA",
          style: {
            border: {
              bottom: { style: "medium", color: { rgb: "00000000" } },
              top: { style: "medium", color: { rgb: "00000000" } },
              left: { style: "medium", color: { rgb: "00000000" } },
              right: { style: "medium", color: { rgb: "00000000" } },
            },
          },
        },
        {
          value: "NA",
          style: {
            border: {
              bottom: { style: "medium", color: { rgb: "00000000" } },
              top: { style: "medium", color: { rgb: "00000000" } },
              left: { style: "medium", color: { rgb: "00000000" } },
              right: { style: "medium", color: { rgb: "00000000" } },
            },
          },
        },
      ]);
    }

    return (
      <ExcelFile
        filename={"Report" + "_" + startDate + "_" + endDate}
        element={<DownloadButton />}
      >
        <ExcelSheet dataSet={multiDataSet} name="Responses" />
      </ExcelFile>
    );
  }
  return (
    <div style={{ height: "100&" }}>
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "65.5vh" }}
        >
          <div>
            <div className="flex justify-center items-center">
              <ScaleLoader
                color="teal"
                height={60}
                width={6}
                radius={18}
                margin={2}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center mt-28">
          <div>
            <div className="flex justify-center items-center">
              <p className="mr-10 mb-0">
                Select month for which to generate the report:
              </p>
              <RangePicker format="YYYY-MM-DD" onChange={onChange} />
            </div>
            <div className="mt-16">
              <ExportCSV />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MonthEndPayerReport;
