import termsOfUse from "../TermsOf Use.json";


function FAQ_Terms({ onPayerScreen }) {
  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <div className="pt-0 scrollableFAQ">
      <div>
          <div>
            <div className="pl-5 mt-4">
                {termsOfUse["TermsOfUse"].map((item, i) => {
                  return (
                    <div key={i}>
                      <p className="mb-2">
                        {item["text"]}
                      </p>
                      <div>
                        {item["subtext"]}
                        </div>
                      <div>
                        {item["subText"] !== undefined &&
                        item["subText"] !== null &&
                        item["subText"].length !== 0
                          ? item["subText"].map((subText, ind) => {
                              return (
                                <p key={ind} className="mb-1 pl-8">
                                  <b>{alphabets[ind].toLowerCase()}.&nbsp;</b>
                                  {subText}
                                </p>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  );
                })}
              </div>
           </div>
        </div>
     </div>
  );
}
export default FAQ_Terms;
