import { Form, Input, Button, Layout, Checkbox, message } from "antd";
import React, { useEffect, useState } from "react";
import "./login.css";
import ZSLogo from "../../../zslogo.png";
import payerconnect from "../../../payerconnect.png";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { APP_CONSTANTS } from "../../../Utils/Constant";

function Login({ SurveyStore, setOnLoginPage }) {
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setloggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    //check if user is already logged in
    let appInfo = JSON.parse(sessionStorage.getItem("appInfo"));
    if (appInfo !== null) {
      axios
        .get(
          APP_CONSTANTS.API_URL +
            "/AuthenticateLogin?Username=" +
            appInfo["username"]
        )
        .then((response) => {
          console.log("Checking for username", appInfo["username"]);
          if (response.data.status === 200) {
            console.log("validated");
            console.log("response.data.result", response.data.result);
            SurveyStore.setClientId(response.data.result["clientId"]);
            SurveyStore.setTeamName(response.data.result["team_name"]);
            console.log(response.data.result["team_name"]);
            //update session storage with client id and isAdmin
            sessionStorage.setItem(
              "appInfo",
              JSON.stringify({
                clientId: SurveyStore.getClientId(),
                showNavFooter: true,
                isAdmin: response.data.result["isAdmin"],
                creditsBalance: response.data.result["creditsBalance"],
                clientName: response.data.result["clientName"],
                username: appInfo["username"],
                team_name: SurveyStore.getTeamName(),
              })
            );
            console.log(response.data.result["team_name"]);
            console.log(
              SurveyStore.getTeamName(),
              sessionStorage.getItem("appInfo")
            );
            setOnLoginPage(true);
            navigate("/currentsurveys");
            setIsLoading(false);
            setloggedIn(true);
          } else {
            message.error("Invalid Credentials!");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          message.error("Invalid Credentials!!");
          console.log(err);
          setIsLoading(false);
        });
      navigate("/currentsurveys");
    }
    setIsLoading(false);
  }, []);

  function onFinish(values) {
    setIsLoading(true);
    console.log(values);
    if (values["password"] === "payerconnect@123") {
      const username = values["username"];
      //API to get clientId corresponding to the provided username
      axios
        .get(APP_CONSTANTS.API_URL + "/AuthenticateLogin?Username=" + username)
        .then((response) => {
          console.log("Checking for username", username);
          if (response.data.status === 200) {
            console.log("validated");
            console.log("response.data.result", response.data.result);
            SurveyStore.setClientId(response.data.result["clientId"]);
            SurveyStore.setTeamName(response.data.result["team_name"]);
            //update session storage with client id and isAdmin
            sessionStorage.setItem(
              "appInfo",
              JSON.stringify({
                clientId: SurveyStore.getClientId(),
                showNavFooter: true,
                isAdmin: response.data.result["isAdmin"],
                creditsBalance: response.data.result["creditsBalance"],
                clientName: response.data.result["clientName"],
                username: username,
                team_name: response.data.result["team_name"],
              })
            );
            console.log(response.data.result["team_name"]);
            console.log(
              SurveyStore.getTeamName(),
              sessionStorage.getItem("appInfo")
            );
            setOnLoginPage(true);
            navigate("/currentsurveys");
            setIsLoading(false);
            setloggedIn(true);
          } else {
            message.error("Invalid Credentials!");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          message.error("Invalid Credentials!!");
          console.log(err);
          setIsLoading(false);
        });
    } else {
      message.error("Invalid Credentials!");
      setIsLoading(false);
    }
  }
  return (
    <div>
      {isLoading ? (
        !loggedIn ? (
          <div
            className="flex justify-center items-center"
            style={{ height: "100vh" }}
          >
            <div>
              <div className="flex justify-center items-center">
                <ScaleLoader
                  color="teal"
                  height={60}
                  width={6}
                  radius={18}
                  margin={2}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex justify-center items-center"
            style={{ height: "90vh" }}
          >
            <div>
              <div className="flex justify-center items-center">
                <ScaleLoader
                  color="teal"
                  height={60}
                  width={6}
                  radius={18}
                  margin={2}
                />
              </div>
              <p className="text-black font-semibold text-xl mt-4">
                Organising surveys, please wait...
              </p>
            </div>
          </div>
        )
      ) : (
        <div
          className="globeBackground flex justify-center items-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="loginCard p-2">
            <div className="flex justify-center pt-16">
              <div className="my-1.5 mr-2">
                <img
                  src={ZSLogo}
                  alt="ZS"
                  style={{
                    width: "2.8rem",
                    padding: "0.3rem",
                    marginLeft: "0.2rem",
                  }}
                />
              </div>
              <div className="flex text-3xl text-black pt-4">
                <p className="text-gray-600 mr-2">ZS</p>
                <b>Payer Connect</b>
                <p
                  className="font-bold ml-1"
                  style={{
                    verticalAlign: "super",
                    fontSize: "0.6rem",
                    marginTop: "-0.3rem",
                  }}
                >
                  {" "}
                  TM
                </p>
              </div>
              <div className="w-1/6 ml-2 pt-4">
                <img
                  src={payerconnect}
                  alt="payerconnect"
                  style={{
                    width: "3rem",
                    height: "2.8rem",
                    // padding: "0.3rem",
                    marginLeft: "0.2rem",
                  }}
                />
              </div>
            </div>
            {/* <div className="border-b-2 flex justify-center mx-10 mt-2 border-gray-400 "></div> */}
            <div className="px-8 mt-12">
              <Form name="login" onFinish={onFinish}>
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input
                    prefix={
                      <UserOutlined
                        style={{ color: "#27a6a4", marginRight: "0.5rem" }}
                      />
                    }
                    placeholder="Username"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    prefix={
                      <LockOutlined
                        style={{ color: "#27a6a4", marginRight: "0.5rem" }}
                      />
                    }
                    placeholder="Password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
