import { Row, Col, Progress } from "antd";
import SummaryQuestionCards from "../../component/SummaryQuestionCards";
function SurveySummaryStatistics({
  basicSurveyData,
  SurveyStore,
  responseData,
}) {
  const storeData = SurveyStore.returnData();
  // iterate over each question, determine its question type and render the charts accordingly
  // render pie chart when split is none for single select MCQs
  // render bar charts for Multi-select MCQs for all splits; and for single select MCQs when split is not none
  // render comment list box for open-text responses
  return (
    <div className="px-36 mt-2 pb-2" style={{ minHeight: "65vh" }}>
      <div className="mb-2">
        {storeData.map((item, i) => {
          let questionResponses = [];
          for (let j = 0; j < responseData.length; j++) {
            if (
              responseData[j]["quesID"] === item["quesID"] &&
              responseData[j]["payerResponses"].length !== 0
            )
              questionResponses.push(responseData[j]);
          }
          if (
            questionResponses !== null &&
            questionResponses.length !== 0 &&
            questionResponses[0] !== null &&
            questionResponses[0]["quesTitle"] === "Optional"
          )
            return "";
          return (
            <SummaryQuestionCards
              responseData={questionResponses}
              serial={i + 1}
              data={item}
            />
          );
        })}
      </div>
    </div>
  );
}

export default SurveySummaryStatistics;
