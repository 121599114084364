import {
  FormOutlined,
  AppstoreOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import "./CurrentSurveysTable.css";
import axios from "axios";
import { APP_CONSTANTS } from "../../Utils/Constant";

function CurrentSurveysHeader({ CurrentSurveyStore, SurveyStore }) {
  function getTotalSurveys() {
    //function to get count of total surveys created by user
    const currentSurveyData = CurrentSurveyStore.getCurrentSurveyData();
    if (currentSurveyData === null) return 0;
    return currentSurveyData.length;
  }

  function downloadSummary() {
    //function to download the summary of all surveys created by user in excel format
    message.loading("Downloading", [1]);
    axios
      .get(
        APP_CONSTANTS.API_URL +
          "/DownloadSurveyExcel?ClientId=" +
          CurrentSurveyStore.getCurrentSurveyData()[0]["createdBy"],
        {
          method: "GET",
          responseType: "blob", // important
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        var currentdate = new Date();
        var datetime =
          String(currentdate.getDate()) +
          "-" +
          String(currentdate.getMonth() + 1) +
          "-" +
          String(currentdate.getFullYear());
        link.setAttribute("download", "Summary_" + datetime + ".xlsm");
        document.body.appendChild(link);
        link.click();
      });
  }

  function getCompletedSurveys() {
    //function to get count of total completed surveys created by user
    const currentSurveyData = CurrentSurveyStore.getCurrentSurveyData();
    if (currentSurveyData === null) return 0;
    let completedSurveys = 0;
    for (let i = 0; i < currentSurveyData.length; i++) {
      if (currentSurveyData[i]["surveyStatus"][0] === "Completed")
        completedSurveys += 1;
    }
    return completedSurveys;
  }

  function getInProgressSurveys() {
    //function to get count of total surveys which are in-progress
    const currentSurveyData = CurrentSurveyStore.getCurrentSurveyData();
    if (currentSurveyData === null) return 0;
    return getTotalSurveys() - getCompletedSurveys();
  }

  function getUserCredits() {
    //function to get count of total surveys which are in-progress
    const creditsBalance = JSON.parse(sessionStorage.getItem("appInfo"))[
      "creditsBalance"
    ];
    if (creditsBalance === null || creditsBalance === undefined) return "NA";
    return creditsBalance;
  }

  return (
    <div className="flex justify-start banner" style={{ height: "5rem" }}>
      <div
        style={{ height: "3.25rem" }}
        className="flex justify-start items-center mt-3 pl-5 w-4/12 font-semibold text-white text-2xl"
      >
        Survey Dashboard
      </div>

      <div
        style={{ height: "3.5rem" }}
        className="flex justify-end mt-2 pl-4 w-2/3 my-2"
      >
        <div className="w-1/5 pl-8 mt-1 flex border-r-2 border-white ">
          <div className="mt-2 mx-3">
            <AppstoreOutlined style={{ fontSize: 20, color: "whitesmoke" }} />
          </div>
          <div className=" mt-2 text-xl" style={{ color: "whitesmoke" }}>
            {getTotalSurveys()}
            <p className="text-xs">Total Surveys</p>
          </div>
        </div>
        <div className="w-1/5 mt-1 ml-4 flex  border-r-2  border-white ">
          <div className="mt-2 mx-3">
            <CheckCircleOutlined
              style={{ fontSize: 20, color: "whitesmoke" }}
            />
          </div>
          <div className="mt-2 text-xl" style={{ color: "whitesmoke" }}>
            {getCompletedSurveys()}
            <p className="text-xs">Completed Surveys</p>
          </div>
        </div>
        <div className="w-1/5 mt-1 ml-4 flex  border-r-2  border-white ">
          <div className="mt-2 mx-3">
            <FormOutlined style={{ fontSize: 20, color: "whitesmoke" }} />
          </div>
          <div className="mt-2 text-xl" style={{ color: "whitesmoke" }}>
            {getInProgressSurveys()}
            <p className="text-xs">In-progress Surveys</p>
          </div>
        </div>
        {getUserCredits() > 50 ? (
          <div className="w-1/5 mt-1 ml-4 flex  border-r-2  border-white ">
            <div className="mt-3 mx-3">
              <i
                className="fa-solid fa-coins"
                style={{ color: "whitesmoke", fontSize: 17 }}
              ></i>
            </div>

            <div className="mt-2 text-xl" style={{ color: "whitesmoke" }}>
              <p className="mb-0">{getUserCredits()}</p>
              <p className="text-xs">Credits Remaining</p>
            </div>
          </div>
        ) : (
          <div className="w-1/5 mt-1 ml-4 flex  border-r-2  border-white ">
            <div className="mt-3 mx-3">
              <i
                className="fa-solid fa-coins"
                style={{ color: "red", fontSize: 17 }}
              ></i>
            </div>

            <div className="mt-2 text-xl" style={{ color: "red" }}>
              <p className="mb-0 text-red-400">{getUserCredits()}</p>
              <p className="text-xs text-red-400">Credits Remaining</p>
            </div>
          </div>
        )}

        <div className="w-1/6 mt-1 ml-4 flex border-white">
          <div className="mt-2 mx-4">
            <DownloadOutlined
              onClick={downloadSummary}
              style={{
                fontSize: 20,
                color: "whitesmoke",
                cursor: "pointer",
              }}
            />
          </div>
          <div className="mt-2 text-xl" style={{ color: "whitesmoke" }}>
            <p
              className="text-xs mt-1"
              onClick={downloadSummary}
              style={{ cursor: "pointer" }}
            >
              Download <br /> Summary
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CurrentSurveysHeader;
