import { Menu } from "antd";
import { useState, useEffect } from "react";
import AddCredits from "./AddCredits";
import AddUser from "./AddUser";
import { useNavigate } from "react-router-dom";
import MonthEndPayerReport from "./MonthEndPayerReport";
import ConsolidatedReport from "./ConsolidatedReport";
import ManagePayer from "./ManagePayer";
import "antd/dist/antd.css";
function Admin({ SurveyStore }) {
  const [keySelected, setKeySelected] = useState("1");
  const navigate = useNavigate();

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("appInfo")) === null) {
      navigate("/"); //usr is not logged in
    } else if (!JSON.parse(sessionStorage.getItem("appInfo"))["isAdmin"]) {
      //if user is not admin
      SurveyStore.increaseNavigationCounter();
      navigate("/invalid");
    } else if (
      SurveyStore.getNavigationCounter() === 0 ||
      JSON.parse(sessionStorage.getItem("appInfo")) === null ||
      JSON.parse(sessionStorage.getItem("appInfo"))["clientId"] === null ||
      JSON.parse(sessionStorage.getItem("appInfo"))["clientId"] === undefined ||
      JSON.parse(sessionStorage.getItem("appInfo"))["clientId"] === 0
    ) {
      navigate("/"); // if user is not logged in or page is refreshed navigate to homepage
    }
  }, []);

  function handleMenuClick(e) {
    setKeySelected(e.key);
  }
  return (
    <div style={{ minHeight: "87.5vh" }}>
      <div className="px-32 bg-white flex">
        <div>
          <Menu
            onClick={handleMenuClick}
            defaultSelectedKeys={"1"}
            mode="horizontal"
            disabledOverflow="false"
            
          >
            <Menu.Item key="1">Add User</Menu.Item>
            <Menu.Item key="2">Add Credits</Menu.Item>
            <Menu.Item key="3">Payer Report</Menu.Item>
            <Menu.Item key="4">Consolidated Report</Menu.Item>
            <Menu.Item key="5">Manage Payer</Menu.Item>
          </Menu>
        </div>
      </div>
      <div className="mt-12 px-36">
        {keySelected === "1" ? (
          <AddUser SurveyStore={SurveyStore} />
        ) : keySelected === "2" ? (
          <AddCredits />
        ) : keySelected === "3" ? (
          <MonthEndPayerReport />
          ) : keySelected === "4" ? (
          <ConsolidatedReport />
        ) : (
          <ManagePayer />
        )}
      </div>
    </div>
  );
}

export default Admin;
