import React from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import CustomRightPanel from "./CustomRightPanel";

import { message } from "antd";

// array which stores those options, above which, a new option shall be added
const leastPriorityOptions = [
  "Other",
  "None",
  "No Change",
  "None of the above",
  "No Impact",
];

//to move the contents of the array when the user drags a card
const arrayMove = (array, from, to) => {
  array = array.slice();
  const arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };
  arrayMoveMutate(array, from, to);
  return array;
};

const DragHandle = sortableHandle(() => (
  <span>
    <i className="fa fa-bars" aria-hidden="true"></i>
  </span>
));

const SortableItem = sortableElement(
  ({ value, serial, removeCard, removeFunction, addMoreItmesFunction }) => (
    <CustomRightPanel
      serial={serial}
      DragHandle={DragHandle}
      removeCard={removeCard}
      value={value}
      removeFunction={removeFunction}
      addMoreItmesFunction={addMoreItmesFunction}
    />
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const SortableItems = ({ items, setItems, filterQuesTextHandler }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex));
  };

  //remove question card
  const removeCard = (cardId) => {
    items.splice(cardId, 1);
    setItems([...items]);
  };

  //remove an option from a question
  const removeFunction = (value, cardId) => {
    const temp = [...items];
    delete temp[cardId].data[value];
    setItems([...temp]);
  };

  //add an option for a question
  const addMoreItmesFunction = (cardId) => {
    const data = Object.keys(items[cardId].data);
    // add a check for duplicate or blank responses
    for (let i = 0; i < data.length; i++)
      for (let j = i + 1; j < data.length; j++)
        if (
          items[cardId].data[data[j]].toLowerCase() ===
            items[cardId].data[data[i]].toLowerCase() ||
          items[cardId].data[data[j]] === ""
        ) {
          message.error(
            "Some Responses are blank or duplicate. Please Check!",
            [1.2]
          );
          return;
        }

    if (Object.keys(items[cardId].data).length === 0) {
      // add new option at first position
      items[cardId].data[Object.keys(items[cardId].data).length] = "";
    } else {
      // else add new option at new key
      const temp = Object.keys(items[cardId].data).map((str) => {
        return Number(str);
      });
      let key = Math.max(...temp);

      // check if the options contain an option from leatPriorityOptions array
      // if yes, find the new position to insert the new option and move rest options to respective positions
      // if no, insert at last position
      let indexKey = -1;
      let options = Object.keys(items[cardId].data);
      let check = false;

      //check if there is an option from leatPriorityOptions array
      for (let i = 0; i < options.length; i++) {
        for (let j = 0; j < leastPriorityOptions.length; j++) {
          let item = leastPriorityOptions[j];
          if (
            items[cardId].data[options[i]].toLowerCase() === item.toLowerCase()
          ) {
            indexKey = Number(options[i]);
            check = true;
            break;
          }
        }
        if (check) break;
      }

      if (indexKey >= 0) {
        let toMoveKeys = [];
        let toMoveValues = [];
        for (let i = 0; i < options.length; i++) {
          if (Number(options[i]) >= indexKey) {
            toMoveKeys.push(options[i]);
            toMoveValues.push(items[cardId].data[options[i]]);
          }
        }
        //insert at found position
        items[cardId].data[indexKey] = "";
        //move the least priority options to respective positions
        for (let i = 0; i < toMoveKeys.length; i++) {
          const newKey = Number(toMoveKeys[i]) + 1;
          items[cardId].data[newKey] = toMoveValues[i];
        }
      } else {
        //insert at last position
        items[cardId].data[key + 1] = "";
      }
    }
    setItems([...items]);
  };

  return (
    <SortableContainer onSortEnd={onSortEnd} useDragHandle>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value}
          serial={index}
          removeCard={removeCard}
          removeFunction={removeFunction}
          addMoreItmesFunction={addMoreItmesFunction}
        />
      ))}
    </SortableContainer>
  );
};

export default SortableItems;
