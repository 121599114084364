import ZSLogo from "../../zslogo.png";
import payerconnect from "../../payerconnect.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ClosedSurvey({ PayerStatusStore }) {
  const navigate = useNavigate();

  useEffect(() => {
    // if page is refreshed navigate to payer status screen
    if (PayerStatusStore.getNavigationCounter() === 0) {
      let navigateTo = "";

      if (
        JSON.parse(sessionStorage.getItem("surveyLink")) === null ||
        JSON.parse(sessionStorage.getItem("surveyLink"))["surveyLink"] ===
          null ||
        JSON.parse(sessionStorage.getItem("surveyLink"))["surveyLink"] ===
          undefined ||
        JSON.parse(sessionStorage.getItem("surveyLink"))["surveyLink"] === ""
      )
        navigateTo =
          "/payerstatus"; //if there is no surveyLink redirect to home
      else {
        navigateTo =
          "/payerstatus" +
          JSON.parse(sessionStorage.getItem("surveyLink"))["surveyLink"];
      }

      navigate(navigateTo);
    }
  });
  return (
    <div style={{ minHeight: "100vh" }}>
      <div
        style={{ paddingTop: "30vh" }}
        className="flex items-center justify-center"
      >
        <div style={{ width: "30rem" }}>
          <div className="flex justify-center">
            <img
              src={ZSLogo}
              style={{
                width: "3rem",
                height: "5rem",
                marginRight: "0.5rem",
                padding: "0.3rem",
                marginLeft: "0.2rem",
              }}
            ></img>

            <div className="flex font-extrabold text-3xl mt-5">
              <p className="font-medium text-gray-600 ">ZS&nbsp;</p> Payer
              Connect
              <p
                className="font-bold ml-1"
                style={{
                  verticalAlign: "super",
                  fontSize: "0.6rem",
                  marginTop: "-0.3rem",
                }}
              >
                {" "}
                TM
              </p>
            </div>
            <div className="my-0 pt-3">
              <img
                src={payerconnect}
                alt="payerconnect"
                style={{
                  width: "3.5rem",
                  height: "3rem",
                  // padding: "0.3rem",
                  marginLeft: "0.5rem",
                }}
              />
            </div>
          </div>
          <div className="text-center">
            <p className="text-xl">This survey has been closed!</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClosedSurvey;
