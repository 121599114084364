import { Select, List, message, Avatar, Skeleton, Divider } from "antd";

//displays the opentext responses in a scrollable box
export default function CommentList({ data, scroll }) {
  return (
    <div className={`mt-3 bg-gray-50 ${scroll ? "scrollable" : ""}`}>
      <List
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item style={{ borderBottom: "1px solid gray" }}>
            <div>
              <p className="text-yellow-600 text-sm mb-1 font-semibold">
                {item["country"]}, {item["type"]}, {item["role"]} -{" "}
                {item["payerId"]}
              </p>

              {item["comment"] === null ? (
                <p className="mb-0 text-xs italic text-red-800">
                  No comments provided!
                </p>
              ) : (
                <p className="mb-0" style={{ wordBreak: "break-all" }}>
                  {item["comment"]}
                </p>
              )}
            </div>
          </List.Item>
        )}
      />
    </div>
  );
}
