import { Upload, message, Button } from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import { UploadProps } from "antd";
import { useState } from "react";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import ReactExport from "react-data-export";

//component which generates a consolidated Payer Summary, given multiple month end reports
function ConsolidatedReport() {
  const [uploadedFileNames, setUploadedFileNames] = useState([]);
  const [consolidatedReportData, setConsolidatedReportData] = useState([]);

  function DownloadButton() {
    //the download button and icon to be displayed on screen
    if (consolidatedReportData.length === 0) {
      return (
        <div>
          <Button type="primary" disabled>
            Generate Consolidated Report
          </Button>
        </div>
      );
    }
    return (
      <div>
        <Button
          type="primary"
          onClick={() => message.loading("Downloading", [0.8])}
        >
          Generate Consolidated Report
        </Button>
      </div>
    );
  }

  function ExportCSV() {
    //monthly report in excel format
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    //add columns of documents in columns attribute
    //add responses in data attribute
    const multiDataSet = [
      {
        columns: [
          {
            title: "Payer Country",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Payer Type",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Payer Expertise",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Payer E-Mail Address",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Surveys Accepted",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
          {
            title: "Questions Answered",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
        ],
        data: [],
      },
    ];

    for (let i = 0; i < consolidatedReportData.length; i++) {
      let temp = [];
      temp.push({
        value: consolidatedReportData[i]["country"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: consolidatedReportData[i]["type"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: consolidatedReportData[i]["role"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: consolidatedReportData[i]["mail"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: consolidatedReportData[i]["acceptedSurveys"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      temp.push({
        value: consolidatedReportData[i]["questionsAnswered"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      multiDataSet[0].data.push(temp);
    }
    return (
      <ExcelFile filename={"ConsolidatedReport"} element={<DownloadButton />}>
        <ExcelSheet dataSet={multiDataSet} name="Responses" />
      </ExcelFile>
    );
  }

  //function which handles file selection from pc
  function onDrop(acceptedFiles) {
    // add check for uploaded files
    if (acceptedFiles.length > 1) {
      message.error("Please select one file at a time");
      return;
    }

    if (uploadedFileNames.length >= 10) {
      message.error("You can select upto 10 files!");
      return;
    }

    //validate file extension
    if (
      acceptedFiles[0]["type"] !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      message.error("Please upload only .xlsx files!");
      return;
    }

    //duplicacy check
    for (let i = 0; i < uploadedFileNames.length; i++) {
      if (uploadedFileNames[i].name === acceptedFiles[0]["name"]) {
        message.error("File already selected!");
        return;
      }
    }
    fileChange(acceptedFiles);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function fileChange(file) {
    let temp = [...uploadedFileNames];
    for (let i = 0; i < file.length; i++) temp.push(file[i]);
    setUploadedFileNames([...temp]);
    const [tempFile] = file;
    const reader = new FileReader();

    //read the contents of the files selected, without storing the file anywhere
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });

      //preprocessing the data
      let tempData = data.split("\n"); //remove line breaks
      tempData.splice(0, 1); //remove first row of headers
      let reportData = [...consolidatedReportData];
      tempData.map((item, i) => {
        if (item === ",,,,,") return; //remove blank fields
        let tempItem = item.split(",");
        let tempObject = {
          country: tempItem[0],
          type: tempItem[1],
          role: tempItem[2],
          mail: tempItem[3],
          acceptedSurveys: Number(tempItem[4]),
          questionsAnswered: Number(tempItem[5]),
        };
        reportData.push(tempObject);
      });

      //accumulate the total of acceptedSurveys and questionsAnswered, grouped on payer's mail
      var helper = {};
      var result = reportData.reduce(function (r, o) {
        // var key = o.country + "-" + o.type + "-" + o.role + "-" + o.mail;
        var key = o.mail;

        if (!helper[key]) {
          helper[key] = Object.assign({}, o); // create a copy of o
          r.push(helper[key]);
        } else {
          helper[key].acceptedSurveys += o.acceptedSurveys;
          helper[key].questionsAnswered += o.questionsAnswered;
        }

        return r;
      }, []);
      setConsolidatedReportData(result);
    };
    reader.readAsBinaryString(tempFile);
  }

  return (
    <div>
      {uploadedFileNames.length === 0 ? (
        <div
          className="w-full bg-gray-200 border-2 border-green-600 flex items-center justify-center text-black text-xl"
          style={{ cursor: "pointer", minHeight: "45vh" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div>
            <div className="flex justify-center">
              <FileAddOutlined style={{ fontSize: "4rem", color: "#27a6a4" }} />
            </div>
            <p className="mt-2 mb-0">Click to select files</p>
          </div>
        </div>
      ) : (
        <div
          className="w-full px-8 pt-8 border-2 border-green-600 flex  text-black "
          style={{ cursor: "pointer", minHeight: "45vh" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div className="w-3/4">
            <p className="text-xl mb-1 font-bold text-green-600">
              Files Selected
            </p>
            {uploadedFileNames.map((item, i) => {
              return (
                <p key={i} className="mb-1">
                  {i + 1}. {item["name"]}
                </p>
              );
            })}
          </div>
          <div className="w-1/4 mb-8 flex items-center justify-start">
            <div>
              <div className="flex justify-center">
                <FileAddOutlined
                  style={{ fontSize: "4rem", color: "#27a6a4" }}
                />
              </div>
              <p className="mt-2 mb-0 flex justify-center">
                Click to add more files
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="flex w-full justify-center items-center mt-12">
        {consolidatedReportData.length === 0 ? (
          <Button type="primary" disabled>
            Generate Consolidated Report
          </Button>
        ) : (
          <ExportCSV />
        )}
      </div>
    </div>
  );
}

export default ConsolidatedReport;
