import { action, toJS, computed, makeObservable, observable } from "mobx";

export class QuestionBankStore {
  //object to store the question bank
  QuestionBankData = [];

  constructor() {
    makeObservable(this, {
      QuestionBankData: observable,
      addQuestionBank: action,
      getQuestionBank: action,
    });
  }

  addQuestionBank(data) {
    if (this.QuestionBankData.length === 0) {
      this.QuestionBankData.push(data);
    }
  }
  getQuestionBank() {
    return toJS(this.QuestionBankData);
  }
}

export default QuestionBankStore;
