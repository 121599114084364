import { action, toJS, makeObservable, observable } from "mobx";

export default class PayerStatusStore {
  PayerStatusData = []; //object to store the payer details and the survey link
  navigationCounter = 0; //how many times the page has been redirected, to keep a check on refresh

  constructor() {
    makeObservable(this, {
      PayerStatusData: observable,
      addPayerStatusData: action,
      getPayerStatusData: action,
      increaseNavigationCounter: action,
      getNavigationCounter: action,
    });
  }

  increaseNavigationCounter() {
    this.navigationCounter++;
  }
  getNavigationCounter() {
    return this.navigationCounter;
  }

  addPayerStatusData(data) {
    this.PayerStatusData.push(data);
  }
  getPayerStatusData() {
    return toJS(this.PayerStatusData);
  }
}
