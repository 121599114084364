import Stepper from "../../component/Stepper";
import NavBarPayer from "../../component/NavBarPayer";
import {
  Radio,
  Checkbox,
  Input,
  Card,
  Space,
  Form,
  Button,
  message,
  Select,
  Modal,
} from "antd";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APP_CONSTANTS } from "../../Utils/Constant";
import { InfoCircleOutlined } from "@ant-design/icons";
import MainFooter from "../../component/MainFooter";
const { Option } = Select;

//API to the survey's question data for a particular payer
const getSurveyData = async (surveyLink) => {
  let ResultArray = [];
  await axios
    .get(
      APP_CONSTANTS.API_URL + "/SurveyQuestions(Payer)?surveyLink=" + surveyLink
    )
    .then((res) => {
      const result = res.data.result;
      ResultArray = result;
      console.log("Result: from api", result);
    })
    .catch((err) => {
      console.log({ err });
    });
  return ResultArray;
};

function PayerSurvey({ PayerStatusStore }) {
  const storeData = PayerStatusStore.getPayerStatusData();
  const [isLoading, setIsLoading] = useState(true);
  const [loadedData, setLoadedData] = useState([]);
  const [initialLoadedData, setInitialLoadedData] = useState([]);
  const [renderRanks, setRenderRanks] = useState([]);
  const [button, setButton] = useState("Submit");
  const [check, setCheck] = useState(false);
  const [disableDropdown, setDisableDropdown] = useState([]);
  const [prevRank, setPrevRank] = useState(false);
  const [isModalVisibleCancel, setIsModalVisibleCancel] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // if page is refreshed navigate to homepage
    if (PayerStatusStore.getNavigationCounter() === 0) {
      let navigateTo = "";

      if (
        JSON.parse(sessionStorage.getItem("surveyLink")) === null ||
        JSON.parse(sessionStorage.getItem("surveyLink"))["surveyLink"] ===
          null ||
        JSON.parse(sessionStorage.getItem("surveyLink"))["surveyLink"] ===
          undefined ||
        JSON.parse(sessionStorage.getItem("surveyLink"))["surveyLink"] === ""
      )
        navigateTo = "/payerstatus";
      //if there is no surveyLink redirect to home
      else {
        navigateTo =
          "/payerstatus" +
          JSON.parse(sessionStorage.getItem("surveyLink"))["surveyLink"];
      }

      navigate(navigateTo);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    if (
      storeData !== null &&
      storeData !== undefined &&
      storeData.length !== 0
    ) {
      const surveyLink = storeData[0]["surveyLink"];
      getSurveyData(surveyLink).then((output) => {
        if (output !== null && output !== undefined) {
          setLoadedData(output);
          setInitialLoadedData(output);
          preprocessInitialData(output);
        }
        console.log("output", output);
      });
    } else {
      setCheck(true);
      if (check) {
        message.error("No data found");
      }
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // to setup ranks for each question irrelevant of questype *just for easier referencing*
    // only ranking question will have a boolean rank array
    // ranks would be boolean to check whether to disable the option or not
    let temp = [];
    if (loadedData !== null) {
      for (let i = 0; i < loadedData.length; i++) {
        let t = [];
        if (
          loadedData[i]["responseLimit"] !== null &&
          loadedData[i]["responseLimit"] !== undefined
        ) {
          for (let j = 0; j < loadedData[i]["responseLimit"]; j++) {
            t.push(false);
          }
        }
        temp.push(t);
      }
      let tempDisable = [];
      for (let i = 0; i < loadedData.length; i++) {
        tempDisable.push(false);
      }
      setDisableDropdown(tempDisable);
      setRenderRanks([...temp]);
    }
  }, [loadedData]);

  const handleCancel = () => {
    setIsModalVisibleCancel(false);
  };

  function handleOk() {
    setIsModalVisibleCancel(false);
    setIsLoading(true);
    let sendData = getSendData(formValues);
    console.log("Final data to DB", sendData);

    //API to save responses entered by the payer
    axios
      .post(APP_CONSTANTS.API_URL + "/SaveResponses?status=Completed", sendData)
      .then((response) => {
        console.log("Status updated successfully", response);
        if (response.data.status == 200) {
          navigate("/thankyouc");
          PayerStatusStore.increaseNavigationCounter();
        } else message.error("Error!");
      })
      .catch((err) => {
        message.error("Error!");
        console.log(err);
        setIsLoading(false);
      });
  }

  function preprocessInitialData(output) {
    //to populate the fields with existing data
    // for a question which accepts both choice based answers and open text responses,
    // ("[QUESTION_No]" + "choice") denotes the choices selected
    // ("[QUESTION_No]" + "input") denotes the open text response
    setIsLoading(true);
    let tempData = [...output];
    let newData = {};
    let tempRenderRanks = [...renderRanks];
    let tempDisable = [...disableDropdown];
    for (let i = 0; i < tempData.length; i++) {
      if (tempData[i]["quesType"] === 2) {
        newData[String(i + 1)] = tempData[i]["surveyResponses"][0];
      } else if (tempData[i]["quesType"] === 1) {
        newData[String(i + 1)] = tempData[i]["comments"];
      } else if (tempData[i]["quesType"] === 3) {
        let tempKey = String(i + 1) + "choice";
        let tempKey1 = String(i + 1) + "input";
        newData[tempKey1] = tempData[i]["comments"];
        for (
          let k = 0;
          k < Object.keys(tempData[i]["responseOptions"]).length;
          k++
        ) {
          if (
            tempData[i]["responseOptions"][k + 1] ===
            tempData[i]["surveyResponses"][0]
          )
            newData[tempKey] = String(k + 1);
        }
      } else if (tempData[i]["quesType"] === 5) {
        for (
          let k = 0;
          k < Object.keys(tempData[i]["responseOptions"]).length;
          k++
        ) {
          if (
            tempData[i]["responseOptions"][k + 1] ===
            tempData[i]["surveyResponses"][0]
          )
            newData[String(i + 1)] = String(k + 1);
        }
      } else if (tempData[i]["quesType"] === 6) {
        let temp = [];
        for (
          let k = 0;
          k < Object.keys(tempData[i]["responseOptions"]).length;
          k++
        ) {
          for (let j = 0; j < tempData[i]["surveyResponses"].length; j++) {
            if (
              tempData[i]["responseOptions"][k + 1] ===
              tempData[i]["surveyResponses"][j]
            )
              temp.push(String(k + 1));
          }
        }
        newData[String(i + 1)] = temp;
      } else if (tempData[i]["quesType"] === 7) {
        let temp = [];
        let tempKey = String(i + 1) + "choice";
        let tempKey1 = String(i + 1) + "input";
        for (
          let k = 0;
          k < Object.keys(tempData[i]["responseOptions"]).length;
          k++
        ) {
          for (let j = 0; j < tempData[i]["surveyResponses"].length; j++) {
            if (
              tempData[i]["responseOptions"][k + 1] ===
              tempData[i]["surveyResponses"][j]
            )
              temp.push(String(k + 1));
          }
        }
        newData[tempKey] = temp;
        newData[tempKey1] = tempData[i]["comments"];
      } else if (tempData[i]["quesType"] === 4) {
        let temp = [];
        for (let j = 0; j < tempData[i]["responseLimit"]; j++) {
          temp.push(false);
          for (
            let k = 0;
            k < Object.keys(tempData[i]["responseOptions"]).length;
            k++
          ) {
            let key = String(i + 1) + "choice" + String(k + 1);
            if (
              tempData[i]["responseOptions"][k + 1] ===
              tempData[i]["surveyResponses"][j]
            ) {
              newData[key] = j + 1;
              temp[j] = true;
            }
          }
        }
        tempRenderRanks[i] = temp;

        let disableCheck = true;
        for (let j = 0; j < temp.length; j++) {
          if (temp[j] === false) disableCheck = false;
        }
        if (disableCheck) {
          tempDisable[i] = true;
        }
      }
    }
    console.log("After preprocessing data", newData);

    setInitialLoadedData(newData);
    setRenderRanks([...tempRenderRanks]);
    setDisableDropdown(tempDisable);
    setIsLoading(false);
  }

  function filterKeys(arr, query) {
    return arr.filter(function (el) {
      return el.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }

  function getSendData(values) {
    //convert the form data to the format in which data is stored in DB
    let sendData = [];
    for (let i = 0; i < loadedData.length; i++) {
      // 1: "Open-ended", 2: "Rating", 3: "Single-choice + open-ended", 4: "Ranking", 5: "Single-choice",
      // 6: "Multi-select", 7: "Multi-select + open-ended"
      if (loadedData[i]["quesType"] === 3) {
        let temp = {
          payerID: loadedData[i]["payerID"],
          surveyID: loadedData[i]["surveyID"],
          quesID: loadedData[i]["quesID"],
          quesTitle: loadedData[i]["quesTitle"],
          quesText: loadedData[i]["quesText"],
          quesCategory: loadedData[i]["quesCategory"],
          responseLimit: loadedData[i]["responseLimit"],
          quesType: loadedData[i]["quesType"],
          responseOptions: loadedData[i]["responseOptions"],
          surveyResponses: [
            loadedData[i]["responseOptions"][values[String(i + 1) + "choice"]],
          ],
          comments: values[String(i + 1) + "input"],
          ispersonal: loadedData[i]["ispersonal"],
          docs_link: loadedData[i]["docs_link"],
        };
        sendData.push(temp);
      } else if (loadedData[i]["quesType"] === 7) {
        let multiSelectAns = [];
        // add all options selected to multiSelectAns
        if (
          values[String(i + 1) + "choice"] !== undefined &&
          values[String(i + 1) + "choice"] !== null
        ) {
          values[String(i + 1) + "choice"].map((item) => {
            multiSelectAns.push(loadedData[i]["responseOptions"][item]);
          });
        }
        let temp = {
          payerID: loadedData[i]["payerID"],
          surveyID: loadedData[i]["surveyID"],
          quesID: loadedData[i]["quesID"],
          quesTitle: loadedData[i]["quesTitle"],
          quesText: loadedData[i]["quesText"],
          quesCategory: loadedData[i]["quesCategory"],
          responseLimit: loadedData[i]["responseLimit"],
          quesType: loadedData[i]["quesType"],
          responseOptions: loadedData[i]["responseOptions"],
          surveyResponses: multiSelectAns,
          comments: values[String(i + 1) + "input"],
          ispersonal: loadedData[i]["ispersonal"],
          docs_link: loadedData[i]["docs_link"],
        };
        sendData.push(temp);
      } else if (loadedData[i]["quesType"] === 4) {
        //get all keys for the this ranking question
        let tempKeys = filterKeys(
          Object.keys(values),
          String(i + 1) + "choice"
        );

        //remove unnecessary keys
        for (let ind = 0; ind < tempKeys.length; ind++) {
          if (String(tempKeys[ind]).split("c")[0] !== String(i + 1)) {
            tempKeys.splice(ind, 1);
          }
        }

        // remove undefined keys
        let keys = [];
        for (let ind = 0; ind < tempKeys.length; ind++) {
          if (values[tempKeys[ind]] !== undefined) keys.push(tempKeys[ind]);
        }

        //get ranks selected for the keys
        let ranks = [];
        for (let ind = 0; ind < keys.length; ind++) {
          ranks.push(values[keys[ind]]);
        }

        tempKeys = [];
        for (let ind = 0; ind < keys.length; ind++) {
          tempKeys.push(String(keys[ind]).split("e")[1]);
        }

        //Add selected options to an array and index them by the order in which they were ranked
        let orderedRanks = [...ranks];
        for (let ind = 0; ind < ranks.length; ind++) {
          orderedRanks[ranks[ind]] =
            loadedData[i]["responseOptions"][tempKeys[ind]];
        }
        let final = [];
        for (let ind = 0; ind < orderedRanks.length; ind++) {
          if (ind !== 0) final.push(orderedRanks[ind]);
        }
        // add values to the temp object
        let temp = {
          payerID: loadedData[i]["payerID"],
          surveyID: loadedData[i]["surveyID"],
          quesID: loadedData[i]["quesID"],
          quesTitle: loadedData[i]["quesTitle"],
          quesText: loadedData[i]["quesText"],
          quesCategory: loadedData[i]["quesCategory"],
          responseLimit: loadedData[i]["responseLimit"],
          quesType: loadedData[i]["quesType"],
          responseOptions: loadedData[i]["responseOptions"],
          surveyResponses: final,
          comments: loadedData[i]["comments"],
          ispersonal: loadedData[i]["ispersonal"],
          docs_link: loadedData[i]["docs_link"],
        };
        sendData.push(temp);
      } else if (loadedData[i]["quesType"] === 1) {
        let temp = {
          payerID: loadedData[i]["payerID"],
          surveyID: loadedData[i]["surveyID"],
          quesID: loadedData[i]["quesID"],
          quesTitle: loadedData[i]["quesTitle"],
          quesText: loadedData[i]["quesText"],
          quesCategory: loadedData[i]["quesCategory"],
          responseLimit: loadedData[i]["responseLimit"],
          quesType: loadedData[i]["quesType"],
          responseOptions: loadedData[i]["responseOptions"],
          surveyResponses: loadedData[i]["surveyResponses"],
          comments: values[i + 1],
          ispersonal: loadedData[i]["ispersonal"],
          docs_link: loadedData[i]["docs_link"],
        };
        sendData.push(temp);
      } else if (loadedData[i]["quesType"] === 6) {
        let multiSelectAns = [];
        // add all options selected to multiSelectAns
        if (values[i + 1] !== undefined && values[i + 1] !== null) {
          values[i + 1].map((item) => {
            multiSelectAns.push(loadedData[i]["responseOptions"][item]);
          });
        }
        let temp = {
          payerID: loadedData[i]["payerID"],
          surveyID: loadedData[i]["surveyID"],
          quesID: loadedData[i]["quesID"],
          quesTitle: loadedData[i]["quesTitle"],
          quesText: loadedData[i]["quesText"],
          quesCategory: loadedData[i]["quesCategory"],
          responseLimit: loadedData[i]["responseLimit"],
          quesType: loadedData[i]["quesType"],
          responseOptions: loadedData[i]["responseOptions"],
          surveyResponses: multiSelectAns,
          comments: loadedData[i]["comments"],
          ispersonal: loadedData[i]["ispersonal"],
          docs_link: loadedData[i]["docs_link"],
        };
        sendData.push(temp);
      } else {
        let temp = {
          payerID: loadedData[i]["payerID"],
          surveyID: loadedData[i]["surveyID"],
          quesID: loadedData[i]["quesID"],
          quesTitle: loadedData[i]["quesTitle"],
          quesText: loadedData[i]["quesText"],
          quesCategory: loadedData[i]["quesCategory"],
          responseLimit: loadedData[i]["responseLimit"],
          quesType: loadedData[i]["quesType"],
          responseOptions: loadedData[i]["responseOptions"],
          surveyResponses: [loadedData[i]["responseOptions"][values[i + 1]]],
          comments: loadedData[i]["comments"],
          ispersonal: loadedData[i]["ispersonal"],
          docs_link: loadedData[i]["docs_link"],
        };
        sendData.push(temp);
      }
    }
    return sendData;
  }

  function onFinish(values) {
    let sendData = [];
    console.log("values", values);
    // 1: "Open-ended", 2: "Rating", 3: "Single-choice + open-ended", 4: "Ranking", 5: "Single-choice", 6: "Multi-select", 7: "Multi-select + open-ended",
    if (button === "Submit") {
      //check if any of the question's responses are blank
      //there are different check for different question type
      let valueCheck = true;
      for (let i = 0; i < loadedData.length; i++) {
        if (loadedData[i]["quesType"] === 3) {
          if (values[String(i + 1) + "choice"] === undefined) {
            valueCheck = false;
            break;
          }
        } else if (loadedData[i]["quesType"] === 7) {
          if (
            values[String(i + 1) + "choice"] === undefined ||
            values[String(i + 1) + "choice"].length === 0
          ) {
            valueCheck = false;
            break;
          }
        } else if (loadedData[i]["quesType"] === 4) {
          let tempCheck = true;
          //check if any of the option for this ranking question is enabled
          renderRanks[i].forEach((item) => {
            if (item === false) {
              tempCheck = false;
              return;
            }
          });
          if (!tempCheck) {
            valueCheck = false;
            break;
          }
        } else if (loadedData[i]["quesType"] === 2) {
          if (values[String(i + 1)] === undefined) {
            valueCheck = false;
            break;
          }
        } else if (loadedData[i]["quesType"] === 6) {
          if (
            values[String(i + 1)] === undefined ||
            values[String(i + 1)].length === 0
          ) {
            valueCheck = false;
            break;
          }
        } else if (
          loadedData[i]["quesType"] === 1 &&
          loadedData[i]["quesTitle"] === "Optional"
        ) {
          valueCheck = true;
        } else {
          if (values[i + 1] === undefined || values[i + 1] === null) {
            valueCheck = false;
            break;
          }
        }
      }

      if (valueCheck) {
        //display the submit modal
        setIsModalVisibleCancel(true);
        setFormValues(values);
      } else {
        setIsLoading(false);
        message.error("Please provide responses to all questions!");
      }
    } else {
      //save data to db
      setIsLoading(true);
      sendData = getSendData(values);
      console.log("Final data to DB", sendData);

      //API to save the responses to the DB
      //returns a status code, determining the successful saving of data
      axios
        .post(APP_CONSTANTS.API_URL + "/SaveResponses?status=Pending", sendData)
        .then((response) => {
          console.log("Data Sent successfully", response);
          if (response.data.status === 200) message.success("Saved!");
          else message.error("Error!");
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
          setIsLoading(false);
        })
        .then((res) => {
          setIsLoading(false);
        });
    }
  }

  function downloadDocument(fileName) {
    //specify path for file to download
    message.loading("Downloading", [1]);
    //API to download a document, given a proper filepath
    //returns an object, which needs to downloaded in desired format
    axios
      .get(
        APP_CONSTANTS.API_URL +
          "/Home/DownloadFromS3?filePath=" +
          storeData[0]["clientId"] +
          "/" +
          storeData[0]["surveyID"] +
          "/" +
          fileName,
        {
          method: "GET",
          responseType: "blob",
        }
      )
      .then((res) => {
        console.log("From s3", res);
        //downloads the document automatically
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        // const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        //  window.open(fileURL,'_blank');
        //  var w = window.open("");
        // w.document.write(fileURL.outerHTML);
        // w.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(res.data.base64) + "'></iframe>")
      })
      .catch((err) => {
        message.error("Error!");
        console.log({ err });
      });
  }

  const [form] = Form.useForm();

  return (
    <div>
      {storeData === null ||
      storeData === undefined ||
      storeData.length === 0 ? (
        ""
      ) : (
        <div>
          {isLoading ? (
            <div>
              <NavBarPayer />
              <div
                className="flex justify-center items-center"
                style={{ height: "87.5vh" }}
              >
                <ScaleLoader
                  color="teal"
                  height={60}
                  width={6}
                  radius={18}
                  margin={2}
                />
              </div>
              <MainFooter />
            </div>
          ) : check === true ? (
            <div style={{ height: "100vh" }}></div>
          ) : (
            <div>
              <NavBarPayer />
              <div
                className="bannerPayer"
                style={{ backgroundColor: "#0f4d7c", minHeight: "8rem" }}
              >
                <p className="text-white text-sm px-16 py-4">
                  {storeData[0]["surveyDescription"]}
                </p>
              </div>
              <Form
                style={{ paddingBottom: "0.5rem" }}
                form={form}
                name="payerResponses"
                initialValues={initialLoadedData}
                onFinish={onFinish}
              >
                <div style={{ minHeight: "65vh" }} className="pl-16 pr-4 mt-6">
                  {loadedData[0]["quesText"] === "" ? (
                    <div className="w-full text-black flex justify-center items-center mt-4 mb-4 pt-24 text-2xl border-gray-300 border-2 h-96">
                      <p> No question exists</p>
                    </div>
                  ) : (
                    //render each question according to its type
                    <div className="flex">
                      <div
                        style={{ minHeight: "20rem" }}
                        className="w-1/6 mr-4 h-56"
                        // id="reviewquestioncard"
                      ></div>
                      <div className="w-4/6">
                        <p className="text-red-500">
                          Questions marked with * cannot be left blank!
                        </p>
                        <div
                          className="pt-3 pl-4 text-lg"
                          style={{
                            height: "3rem",
                            backgroundColor: "#0a3859",
                            color: "whitesmoke",
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5,
                          }}
                        >
                          <i className="fa-solid fa-arrow-down mr-2"></i>
                          Start Here
                        </div>
                        <div
                          style={{
                            borderRadius: 5,
                            padding: "1rem",
                          }}
                          id="reviewquestioncard"
                          className="mb-4"
                        >
                          Download and read these documents prior to taking the
                          survey.
                          <div className="mt-2">
                            {loadedData[0]["docs_link"] === null ||
                            loadedData[0]["docs_link"] === undefined
                              ? ""
                              : loadedData[0]["docs_link"].map((item) => {
                                  return (
                                    <p
                                      className="my-1"
                                      style={{
                                        cursor: "pointer",
                                        color: "teal",
                                      }}
                                      onClick={() => {
                                        downloadDocument(item);
                                      }}
                                    >
                                      {item}
                                    </p>
                                  );
                                })}
                          </div>
                        </div>
                        {loadedData.map((item, i) => {
                          return (
                            <div
                              id="reviewquestioncard"
                              style={{
                                borderRadius: 5,
                                alignItems: "center",
                                marginBottom: "1rem",
                                padding: "1rem",
                              }}
                            >
                              <div className="mt-2 pl-6 flex">
                                {item["quesTitle"] === "Optional" ? (
                                  ""
                                ) : (
                                  <p className="text-red-500">*&nbsp;</p>
                                )}
                                {item["quesTitle"] === "Optional" ? (
                                  ""
                                ) : (
                                  <p className="mr-2">{i + 1}.</p>
                                )}

                                <p>{item["quesText"]}</p>
                              </div>
                              <hr></hr>
                              <div className="mt-2">
                                {item["quesType"] === 1 ? (
                                  <div className="px-6">
                                    <Form.Item name={i + 1}>
                                      <Input.TextArea
                                        rows={4}
                                        // defaultValue={initialLoadedData[i + 1]}
                                        placeholder="Enter Your Comments Here"
                                      ></Input.TextArea>
                                    </Form.Item>
                                  </div>
                                ) : item["quesType"] === 2 ? (
                                  <Form.Item name={i + 1}>
                                    <Radio.Group>
                                      <div className="flex">
                                        {Object.keys(
                                          item["responseOptions"]
                                        ).map((option, j) => {
                                          return (
                                            <div className="pl-6 my-1 flex">
                                              <Radio value={option}>
                                                {
                                                  item["responseOptions"][
                                                    option
                                                  ]
                                                }
                                              </Radio>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </Radio.Group>
                                  </Form.Item>
                                ) : item["quesType"] === 3 ? (
                                  <div className="px-6">
                                    <Form.Item name={String(i + 1) + "choice"}>
                                      <Radio.Group>
                                        {Object.keys(
                                          item["responseOptions"]
                                        ).map((option, j) => {
                                          return (
                                            <div className="my-1 flex">
                                              <Radio value={option}>
                                                {
                                                  item["responseOptions"][
                                                    option
                                                  ]
                                                }
                                              </Radio>
                                            </div>
                                          );
                                        })}
                                      </Radio.Group>
                                    </Form.Item>
                                    <Form.Item name={String(i + 1) + "input"}>
                                      <Input.TextArea
                                        rows={4}
                                        placeholder="Enter Your Comments Here"
                                      />
                                    </Form.Item>
                                  </div>
                                ) : item["quesType"] === 5 ? (
                                  <Form.Item name={i + 1}>
                                    <Radio.Group>
                                      {Object.keys(item["responseOptions"]).map(
                                        (option, j) => {
                                          return (
                                            <div className="pl-6 my-1 flex">
                                              <Radio value={option}>
                                                {
                                                  item["responseOptions"][
                                                    option
                                                  ]
                                                }
                                              </Radio>
                                            </div>
                                          );
                                        }
                                      )}
                                    </Radio.Group>
                                  </Form.Item>
                                ) : item["quesType"] === 6 ? (
                                  <Form.Item name={i + 1}>
                                    <Checkbox.Group>
                                      {Object.keys(item["responseOptions"]).map(
                                        (option, j) => {
                                          return (
                                            <div className="pl-6 my-1 flex">
                                              <Checkbox value={option}>
                                                {
                                                  item["responseOptions"][
                                                    option
                                                  ]
                                                }
                                              </Checkbox>
                                            </div>
                                          );
                                        }
                                      )}
                                    </Checkbox.Group>
                                  </Form.Item>
                                ) : item["quesType"] === 7 ? (
                                  <div className="px-6">
                                    <Form.Item name={String(i + 1) + "choice"}>
                                      <Checkbox.Group>
                                        {Object.keys(
                                          item["responseOptions"]
                                        ).map((option, j) => {
                                          return (
                                            <div className="my-1 flex">
                                              <Checkbox value={option}>
                                                {
                                                  item["responseOptions"][
                                                    option
                                                  ]
                                                }
                                              </Checkbox>
                                            </div>
                                          );
                                        })}
                                      </Checkbox.Group>
                                    </Form.Item>
                                    <Form.Item name={String(i + 1) + "input"}>
                                      <Input.TextArea
                                        rows={4}
                                        placeholder="Enter Your Comments Here"
                                      />
                                    </Form.Item>
                                  </div>
                                ) : (
                                  <div>
                                    {Object.keys(item["responseOptions"]).map(
                                      (option, k) => {
                                        return (
                                          <div
                                            key={k}
                                            className="pl-6 my-1 flex"
                                          >
                                            <Form.Item
                                              noStyle
                                              name={
                                                String(i + 1) +
                                                "choice" +
                                                String(k + 1)
                                              }
                                            >
                                              <Select
                                                disabled={disableDropdown[i]}
                                                id={"rankSelector" + k}
                                                onSelect={(value) => {
                                                  //update the ranks whether to be disabled or not
                                                  let boolRanks = [
                                                    ...renderRanks,
                                                  ];
                                                  if (
                                                    prevRank !== undefined &&
                                                    prevRank !== null
                                                  ) {
                                                    boolRanks[i][
                                                      prevRank - 1
                                                    ] = false;
                                                  }
                                                  boolRanks[i][
                                                    value - 1
                                                  ] = true;
                                                  setRenderRanks([
                                                    ...boolRanks,
                                                  ]);

                                                  //check if all ranks are selected, if yes disable all dropdowns
                                                  let disableCheck = true;
                                                  for (
                                                    let j = 0;
                                                    j < boolRanks[i].length;
                                                    j++
                                                  ) {
                                                    if (
                                                      boolRanks[i][j] === false
                                                    )
                                                      disableCheck = false;
                                                  }
                                                  if (disableCheck) {
                                                    let tempDisable = [
                                                      ...disableDropdown,
                                                    ];
                                                    tempDisable[i] = true;
                                                    setDisableDropdown(
                                                      tempDisable
                                                    );
                                                  }
                                                }}
                                                onClick={() => {
                                                  setPrevRank(
                                                    Number(
                                                      form.getFieldValue(
                                                        String(i + 1) +
                                                          "choice" +
                                                          String(k + 1)
                                                      )
                                                    )
                                                  );
                                                }}
                                                style={{ width: "4rem" }}
                                              >
                                                {renderRanks[i].length !== 0
                                                  ? renderRanks[i].map(
                                                      (rank, j) => {
                                                        return (
                                                          <Option
                                                            disabled={
                                                              renderRanks[i][j]
                                                            }
                                                            value={j + 1}
                                                          >
                                                            {j + 1}
                                                          </Option>
                                                        );
                                                      }
                                                    )
                                                  : ""}
                                              </Select>
                                            </Form.Item>
                                            <p className="ml-2">
                                              {item["responseOptions"][option]}
                                            </p>
                                          </div>
                                        );
                                      }
                                    )}
                                    <div className="flex justify-end">
                                      <Button
                                        id="clearRanks"
                                        onClick={() => {
                                          //function to clear all ranks for this question
                                          let temp = [...renderRanks];
                                          temp[i].forEach((item, index) => {
                                            temp[i][index] = false;
                                          });
                                          setRenderRanks([...temp]);
                                          const fieldName =
                                            String(i + 1) + "choice";
                                          for (
                                            let a = 0;
                                            a <
                                            Object.keys(item["responseOptions"])
                                              .length;
                                            a++
                                          ) {
                                            let temp = String(
                                              fieldName + String(a + 1)
                                            );
                                            form.setFieldsValue({
                                              [temp]: undefined,
                                            });
                                          }
                                          let tempDisable = [
                                            ...disableDropdown,
                                          ];
                                          tempDisable[i] = false;
                                          setDisableDropdown(tempDisable);
                                        }}
                                      >
                                        Clear
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="w-1/6 text-sm ml-4"></div>
                    </div>
                  )}
                </div>
                <div id="footerOne">
                  <Card size="small">
                    <Space>
                      <Button
                        onClick={() => setButton("Submit")}
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={() => setButton("Draft")}
                        htmlType="submit"
                      >
                        Save as Draft
                      </Button>
                    </Space>
                  </Card>
                </div>
              </Form>
              <MainFooter />
            </div>
          )}

          <Modal
            title="Submit Responses"
            visible={isModalVisibleCancel}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>
              Are you sure you are ready to submit your responses? Please keep
              in mind that all responses will be seen directly by a ZS client,
              which may be a life sciences company.
            </p>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default PayerSurvey;
