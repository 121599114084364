import { Select, Button, message, InputNumber, Modal, Input } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import { APP_CONSTANTS } from "../../Utils/Constant";

//component which allows user to add credits in its environment
function AddCredits() {
  const [credits, setCredits] = useState(100);
  const [teamname, setteamname] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  const { Option } = Select;

  const GetPayerTeams = () => {
    let ResultArray = [];
    axios
      .get(APP_CONSTANTS.API_URL + "/GetPayerTeams")
      .then((res) => {
        console.log("Response: from api", res);
        const result = res.data.result;
        ResultArray = result;
        console.log(ResultArray);
        setDropdownData(ResultArray);
      })
      .catch((err) => {
        console.log({ err });
      });
    return ResultArray;
  };

  useEffect(() => {
    GetPayerTeams();
  }, []);

  const showModal = () => {
    //check if credits are valid
    if (
      credits === null ||
      credits === undefined ||
      credits < 1 ||
      !Number.isInteger(credits)
    ) {
      message.error("Credit should be a whole number");
      return;
    }
    if (teamname === "" || teamname === null) {
      message.error("Enter Team Name");
      return;
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    //update credits in db
    setIsModalVisible(false);
    console.log("credits", credits);
    setIsLoading(true);
    axios
      .post(
        APP_CONSTANTS.API_URL +
          "/AddCredits?Credits=" +
          Number(credits) +
          "&TeamName=" +
          teamname
      )
      .then((response) => {
        console.log("response", response);
        if (response.data.status === 200) {
          message.success("Credits Added!");
          setteamname("");
        } else message.error("Error!");
      })
      .catch((err) => {
        message.error("Error!");
        console.log(err);
      })
      .then((res) => {
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "65.5vh" }}
        >
          <div>
            <div className="flex justify-center items-center">
              <ScaleLoader
                color="teal"
                height={60}
                width={6}
                radius={18}
                margin={2}
              />
            </div>
            <p className="text-black font-semibold text-xl mt-4">
              Updating Credits!
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-center">
            <p className="mb-0 mr-8">
              Enter amount of credits you wish to add:
            </p>
            <InputNumber
              defaultValue={credits}
              onChange={(e) => setCredits(e)}
            />
          </div>
          <div className="flex items-center justify-center">
            <p className="mb-0 mr-8">Enter Team Name:</p>

            <Select
              placeholder="Enter Team Name"
              style={{ width: "228px", marginTop: "10px" }}
              onChange={(e) => setteamname(e)}
              value={teamname}
            >
              {dropdownData.map((val, i) => {
                return <Option key={val}>{val}</Option>;
              })}
            </Select>

            {/* <Input
              placeholder="Enter Team Name"
              onChange={(e) => setteamname(e.target.value)}
              style={{ width: "228px", marginTop: "10px" }}
            /> */}
            {/* defaultValue={teamname}
              onChange={(e) => setteamname(e)} */}
          </div>
          <div className="flex justify-center items-center mt-4">
            <Button
              style={{ width: "6rem" }}
              type="primary"
              onClick={showModal}
            >
              Add
            </Button>
          </div>
        </div>
      )}
      <Modal
        title="Add Credits"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to add credits?</p>
      </Modal>
    </div>
  );
}

export default AddCredits;
