import { action, toJS, computed, makeObservable, observable } from "mobx";

export class SurveyStore {
  //surveyData object to store the details of a survey
  SurveyData = [
    {
      clientID: 0,
      surveyID: 0,
      payerID: 0,
      surveyName: "",
      surveyAsset: "",
      therapyArea: "",
      researchType: "",
      surveyDescription: "",
      deadline: "0001-01-01T00:00:00",
      quesID: 0,
      quesTitle: "",
      quesText: "",
      quesCategory: "",
      quesType: 0,
      responseLimit: 0,
      responseOptions: {},
      customResponses: [""],
      payerResponses: [""],
      payerComment: "",
      docs_link: [],
      ispersonal: false,
      isUserCreated: false,
      isactive: true,
      isdeleted: false,
      order_by: 0,
      is_highlight: false,
      status: "",
      respondant_id: 0,
      team_name: "",
    },
  ];

  ClientId = 0; //clientId of the user
  isEdited = false; //whether survey is being edited or not
  navigationCounter = 0; //how many times the page has been redirected, to keep a check on refresh
  isChangingPayers = false; //whether the user wants to change the set of respondents
  TeamName = "";

  constructor() {
    console.log("Contructor Called", this.TeamName);
    makeObservable(this, {
      SurveyData: observable,
      ClientId: observable,
      TeamName: observable,
      isEdited: observable,
      getClientId: action,
      setClientId: action,
      getTeamName: action,
      setTeamName: action,
      addSurveyFieldsData: action,
      returnData: action,
      addQuestionData: action,
      deleteStoreData: action,
      addQuestionFromSurveyQuestions: action,
      setIsChangingPayers: action,
      getIsChangingPayers: action,
      setIsEdited: action,
      getIsEdited: action,
      deleteQuestions: action,
      addDocsLink: action,
      getDocsLink: action,
      increaseNavigationCounter: action,
      getNavigationCounter: action,
    });
  }

  increaseNavigationCounter() {
    this.navigationCounter++;
  }
  getNavigationCounter() {
    return this.navigationCounter;
  }

  setIsChangingPayers(data) {
    this.isChangingPayers = data;
  }
  getIsChangingPayers() {
    return this.isChangingPayers;
  }

  addDocsLink(data) {
    //function to add documents to docs_link
    for (let i = 0; i < this.SurveyData.length; i++) {
      this.SurveyData[i]["docs_link"] = data;
    }
  }

  getDocsLink() {
    return toJS(this.SurveyData)[0]["docs_link"];
  }

  getClientId() {
    return this.ClientId;
  }
  setClientId(id) {
    this.ClientId = id;
  }
  getTeamName() {
    return JSON.parse(sessionStorage.getItem("appInfo"))["team_name"];
  }
  setTeamName(name) {
    this.TeamName = name;
    console.log(this.TeamName, name);
    console.log("Setting Teamname", this.TeamName);
  }
  getIsEdited() {
    return this.isEdited;
  }
  setIsEdited(value) {
    this.isEdited = value;
  }

  addSurveyFieldsData(data) {
    //add from survey fields screen
    console.log("addsurveyFieldData", this.TeamName);
    if (this.SurveyData.length === 1) {
      this.SurveyData[0]["clientID"] = this.ClientId;
      this.SurveyData[0]["team_name"] = JSON.parse(
        sessionStorage.getItem("appInfo")
      )["team_name"];
      this.SurveyData[0]["surveyName"] = data["surveyName"];
      this.SurveyData[0]["surveyAsset"] = data["surveyAsset"];
      this.SurveyData[0]["therapyArea"] = data["therapyArea"];
      this.SurveyData[0]["surveyDescription"] = data["surveyDescription"];
      this.SurveyData[0]["researchType"] = data["researchType"];
      this.SurveyData[0]["status"] = data["status"];
      //check if survey exists
      if (data["surveyID"] != 0 && data["surveyID"]) {
        console.log("exisintg survey found");
        this.SurveyData[0]["surveyID"] = data["surveyID"];
      }
    } else {
      for (let i = 0; i < this.SurveyData.length; i++) {
        this.SurveyData[i]["surveyName"] = data["surveyName"];
        this.SurveyData[i]["surveyAsset"] = data["surveyAsset"];
        this.SurveyData[i]["therapyArea"] = data["therapyArea"];
        this.SurveyData[i]["surveyDescription"] = data["surveyDescription"];
        this.SurveyData[i]["researchType"] = data["researchType"];
        this.SurveyData[i]["status"] = data["status"];
        this.SurveyData[i]["clientID"] = this.ClientId;
        this.SurveyData[i]["team_name"] = JSON.parse(
          sessionStorage.getItem("appInfo")
        )["team_name"];

        //check if survey exists
        if (data["surveyID"] != 0 && data["surveyID"]) {
          console.log("exisintg survey found");
          this.SurveyData[i]["surveyID"] = data["surveyID"];
        }
      }
    }
    console.log("After adding field data to store", toJS(this.SurveyData));
  }

  addQuestionFromSurveyQuestions(data) {
    //function to add data from survey question screen
    if (data.length === 0) {
      let tempSurveyData = {
        clientID: this.SurveyData[0].clientID,
        surveyID: this.SurveyData[0].surveyID,
        team_name: JSON.parse(sessionStorage.getItem("appInfo"))["team_name"],
        payerID: 0,
        surveyName: this.SurveyData[0].surveyName,
        surveyAsset: this.SurveyData[0].surveyAsset,
        therapyArea: this.SurveyData[0].therapyArea,
        researchType: this.SurveyData[0].researchType,
        surveyDescription: this.SurveyData[0].surveyDescription,
        deadline: "0001-01-01T00:00:00",
        quesID: 0,
        quesTitle: "",
        quesText: "",
        responseLimit: 0,
        quesCategory: "",
        quesType: 0,
        responseOptions: {},
        customResponses: [""],
        payerResponses: [""],
        payerComment: "",
        docs_link: [],
        ispersonal: false,
        isUserCreated: false,
        isactive: true,
        isdeleted: false,
        order_by: 0,
        is_highlight: false,
        status: "",
        respondant_id: 0,
      };
      this.SurveyData.length = 1;
      this.SurveyData[0] = tempSurveyData;
      return;
    }

    // add survey question data from survey question screen
    for (let i = 0; i < data.length; i++) {
      let tempSurveyData = {
        clientID: this.SurveyData[0].clientID,
        surveyID: this.SurveyData[0].surveyID,
        team_name: JSON.parse(sessionStorage.getItem("appInfo"))["team_name"],
        payerID: 0,
        surveyName: this.SurveyData[0].surveyName,
        surveyAsset: this.SurveyData[0].surveyAsset,
        therapyArea: this.SurveyData[0].therapyArea,
        // indication: this.SurveyData[0].indication,
        researchType: this.SurveyData[0].researchType,
        surveyDescription: this.SurveyData[0].surveyDescription,
        deadline: "0001-01-01T00:00:00",
        quesID: 0,
        quesTitle: "",
        quesText: "",
        responseLimit: 0,
        quesCategory: "",
        quesType: 0,
        responseOptions: {},
        customResponses: [""],
        payerResponses: [""],
        payerComment: "",
        docs_link: [],
        ispersonal: false,
        isUserCreated: false,
        isactive: true,
        isdeleted: false,
        order_by: i,
        is_highlight: false,
        status: "",
        respondant_id: 0,
      };
      if (data[i]["isUserCreated"] === true) {
        tempSurveyData["quesText"] = data[i]["mainTitle"];
        tempSurveyData["quesType"] = data[i]["quesType"];
        tempSurveyData["isUserCreated"] = data[i]["isUserCreated"];
        tempSurveyData["customResponses"] = data[i]["customResponses"];
        tempSurveyData["responseLimit"] = data[i]["responseLimit"];
        tempSurveyData["responseOptions"] = data[i]["data"];
        tempSurveyData["docs_link"] = this.SurveyData[0]["docs_link"];
        tempSurveyData["is_highlight"] = data[i]["is_highlight"];
        tempSurveyData["status"] = data[i]["status"];
        if (
          data[i]["quesTitle"] !== undefined ||
          data[i]["quesTitle"] !== "" ||
          data[i]["quesTitle"] !== null
        )
          tempSurveyData["quesTitle"] = data[i]["quesTitle"];
      } else {
        tempSurveyData["quesText"] = data[i]["mainTitle"];
        tempSurveyData["responseOptions"] = data[i]["data"];
        tempSurveyData["quesID"] = data[i]["id"];
        tempSurveyData["quesType"] = data[i]["quesType"];
        tempSurveyData["isUserCreated"] = data[i]["isUserCreated"];
        tempSurveyData["responseLimit"] = data[i]["responseLimit"];
        tempSurveyData["docs_link"] = this.SurveyData[0]["docs_link"];
        tempSurveyData["is_highlight"] = data[i]["is_highlight"];
        tempSurveyData["status"] = data[i]["status"];

        // to add optional questions
        if (
          data[i]["quesTitle"] !== undefined ||
          data[i]["quesTitle"] !== "" ||
          data[i]["quesTitle"] !== null
        )
          tempSurveyData["quesTitle"] = data[i]["quesTitle"];
      }
      if (i === 0) {
        this.SurveyData.length = 1;
        this.SurveyData[0] = tempSurveyData;
      } else {
        this.SurveyData.push(tempSurveyData);
      }
    }
  }

  addQuestionData(data) {
    //add question related data to survey store
    for (let i = 0; i < data.length; i++) {
      let tempSurveyData = {
        clientID: this.SurveyData[0].clientID,
        surveyID: this.SurveyData[0].surveyID,
        team_name: JSON.parse(sessionStorage.getItem("appInfo"))["team_name"],
        payerID: 0,
        surveyName: this.SurveyData[0].surveyName,
        surveyAsset: this.SurveyData[0].surveyAsset,
        therapyArea: this.SurveyData[0].therapyArea,
        researchType: this.SurveyData[0].researchType,
        surveyDescription: this.SurveyData[0].surveyDescription,
        deadline: "0001-01-01T00:00:00",
        quesID: 0,
        quesTitle: "",
        quesText: "",
        quesCategory: "",
        quesType: 0,
        responseLimit: 0,
        responseOptions: {},
        customResponses: [""],
        payerResponses: [""],
        payerComment: "",
        docs_link: [],
        ispersonal: false,
        isUserCreated: false,
        isactive: true,
        isdeleted: false,
        order_by: i,
        is_highlight: false,
        status: "",
        respondant_id: 0,
      };
      tempSurveyData["quesTitle"] = data[i]["quesTitle"];
      tempSurveyData["responseOptions"] = data[i]["responseOptions"];
      tempSurveyData["quesID"] = data[i]["quesID"];
      tempSurveyData["quesType"] = data[i]["quesType"];
      tempSurveyData["isUserCreated"] = data[i]["isUserCreated"];
      tempSurveyData["customResponses"] = data[i]["customResponses"];
      tempSurveyData["quesText"] = data[i]["quesText"];
      tempSurveyData["responseLimit"] = data[i]["responseLimit"];
      tempSurveyData["docs_link"] = data[i]["docs_link"];
      tempSurveyData["is_highlight"] = data[i]["is_highlight"];
      tempSurveyData["status"] = data[i]["status"];
      tempSurveyData["respondant_id"] = data[i]["respondant_id"];
      if (i === 0) {
        this.SurveyData.length = 1;
        this.SurveyData[0] = tempSurveyData;
      } else {
        this.SurveyData.push(tempSurveyData);
      }
    }
    console.log("After question field data to store", toJS(this.SurveyData));
  }

  returnData() {
    console.log("returnData called", toJS(this.SurveyData));
    return toJS(this.SurveyData);
  }

  deleteQuestions() {
    //function to delete all question from store
    let tempStoreData = [
      {
        clientID: this.ClientId,
        team_name: JSON.parse(sessionStorage.getItem("appInfo"))["team_name"],
        surveyID: this.surveyID,
        payerID: 0,
        surveyName: this.surveyName,
        surveyAsset: this.surveyAsset,
        therapyArea: this.therapyArea,
        researchType: this.researchType,
        surveyDescription: this.surveyDescription,
        deadline: "0001-01-01T00:00:00",
        quesID: 0,
        quesTitle: "",
        quesText: "",
        quesCategory: "",
        quesType: 0,
        responseLimit: 0,
        responseOptions: {},
        customResponses: [""],
        payerResponses: [""],
        payerComment: "",
        docs_link: [],
        ispersonal: false,
        isUserCreated: false,
        isactive: true,
        isdeleted: false,
        order_by: 0,
        is_highlight: false,
        status: "",
        respondant_id: 0,
      },
    ];
    this.SurveyData = tempStoreData;
  }

  deleteStoreData() {
    //function to empty the store contents
    let tempStoreData = [
      {
        clientID: this.ClientId,
        team_name: JSON.parse(sessionStorage.getItem("appInfo"))["team_name"],
        surveyID: 0,
        payerID: 0,
        surveyName: "",
        surveyAsset: "",
        therapyArea: "",
        researchType: "",
        surveyDescription: "",
        deadline: "0001-01-01T00:00:00",
        quesID: 0,
        quesTitle: "",
        quesText: "",
        quesCategory: "",
        quesType: 0,
        responseLimit: 0,
        responseOptions: {},
        customResponses: [""],
        payerResponses: [""],
        payerComment: "",
        docs_link: [],
        ispersonal: false,
        isUserCreated: false,
        isactive: true,
        isdeleted: false,
        order_by: 0,
        is_highlight: false,
        status: "",
        respondant_id: 0,
      },
    ];
    this.SurveyData = tempStoreData;
  }
}

export default SurveyStore;
