import { Pie } from "@nivo/pie";
import { Card } from "antd";
import { useEffect, useState } from "react";

function PieChart({ data }) {
  const [renderData, setRenderData] = useState([]);

  //preprocess data in required format
  useEffect(() => {
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      temp.push(data[i]["response"][0]);
    }
    let values = [...new Set(temp)];
    let tempRenderData = [];
    for (let i = 0; i < values.length; i++) {
      let count = 0;
      for (let j = 0; j < data.length; j++) {
        if (data[j]["response"][0] === values[i]) count++;
      }
      tempRenderData.push({
        id: values[i],
        value: count,
      });
    }
    setRenderData(tempRenderData);
  }, []);

  const customColors = [
    "rgb(217,225,242)",
    "rgb(252,228,214)",
    "rgb(255,205,205)",
    "rgb(222,200,238)",
    "rgb(193,255,254)",
    "rgb(255,153,204)",
    "rgb(204,255,204)",
    "rgb(226,239,218)",
    "rgb(255,217,102)",
    "rgb(176,230,230)",
    "rgb(141,231,160)",
    "rgb(255,167,167)",
  ];

  const custom = [
    "#7acfe5", // blue - 1
    "#5fb5db", // blue - 2
    "#3f9abf", // blue -3
    "#1680a4", // blue - 4
    "#ffd090", // orange - 1
    "#ffc26c", // orange - 2
    "#ffb446", // orange - 3
    "#fea610", // orange - 4
  ];

  return (
    <div
      className="flex border-2 pt-4 border-green-500 justify-center"
      style={{ height: "24rem", width: "100%" }}
    >
      <Pie
        data={renderData}
        height={400}
        width={1000}
        margin={{ top: 20, right: 100, bottom: 70, left: 50 }}
        innerRadius={0.5}
        padAngle={1}
        cornerRadius={6}
        colors={custom}
        // borderWidth={1}

        activeOuterRadiusOffset={8}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1]],
        }}
        enableArcLinkLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 4]],
        }}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 50,
            translateY: 0,
            itemsSpacing: 4,
            itemWidth: 50,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}

export default PieChart;
