import { Button } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function UnAuthUser({ SurveyStore }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (SurveyStore.getNavigationCounter() === 0) {
      navigate("/");
    }
  });

  return (
    <div
      className="flex items-center justify-center"
      style={{ minHeight: "87.5vh" }}
    >
      <div>
        {/* <div style={{ minHeight: "19.5rem" }}>
          <img src={publishImage} alt="Survey Published!"></img>
        </div> */}
        <div className="flex justify-center">
          <p className="font-bold text-xl mb-0">
            Unauthorised access! <br></br>
          </p>
        </div>
        <div className="flex justify-center">
          <p className="font-bold text-xl">You cannot access this page.</p>
        </div>
        <div className="mt-2" style={{ paddingInline: "9rem" }}>
          <a
            onClick={() => SurveyStore.deleteStoreData()}
            className="nav-link"
            href="/"
          >
            <Button type="primary">Back to Survey Dashboard</Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default UnAuthUser;
