import { useState } from "react";
import { SortableHandle } from "react-sortable-hoc";
import { Slider, message, Popover, Tooltip } from "antd";

export default function CustomRightPanel({
  serial,
  value,
  removeCard,
  removeFunction,
  editCard,
  addMoreItmesFunction,
}) {
  const DragHandle = SortableHandle(() => (
    <i
      className="fa-solid fa-grip-vertical"
      aria-hidden="true"
      style={{ cursor: "move" }}
    ></i>
  ));
  const [PanelOpen, setPanelOpen] = useState(false);
  const [toEdit, setToEdit] = useState(false);
  const [showRemoveButton, setShowRemoveButton] = useState(false);
  const panelToggle = (event) => {
    setPanelOpen(PanelOpen ? false : true);
  };

  function editCard() {
    setToEdit(toEdit ? false : true);
    setShowRemoveButton(true);
  }

  //function to save updates to the card
  function saveEditCard() {
    //check if responses/questions are blank
    if (value["mainTitle"] === "") {
      message.error("Some Questions are blank. Please Check!", [1.2]);
      return;
    }
    let dataKeys = Object.keys(value["data"]);
    for (let i = 0; i < dataKeys.length; i++)
      if (value["data"][dataKeys[i]] === "") {
        message.error("Some Responses are blank or duplicate. Please Check!", [
          1.2,
        ]);
        return;
      }

    //check for duplicate questions
    for (let i = 0; i < dataKeys.length; i++)
      for (let j = i + 1; j < dataKeys.length; j++)
        if (
          value["data"][dataKeys[j]].toLowerCase() ===
          value["data"][dataKeys[i]].toLowerCase()
        ) {
          message.error(
            "Some Responses are blank or duplicate. Please Check!",
            [1.2]
          );
          return;
        }
    //for ranking questions, check if responseLimit is more than no of options
    if (value["quesType"] === 4) {
      if (dataKeys.length < value["responseLimit"]) {
        message.error("There are less options to rank. Please Check!");
        return;
      }
    }

    setToEdit(toEdit ? false : true);
    setShowRemoveButton(false);
    value["isUserCreated"] = true;
    value["is_highlight"] = true;
    value["quesID"] = 0;
    // add options created to custom responses
    if (value["quesType"] === 2) {
      // modify custom responses for rating based question
      value["customResponses"] = [];
      if (Object.keys(value["data"])[0] === "0")
        value["customResponses"].push(String(value["data"]));
      else
        value["customResponses"].push(
          String([Object.keys(value["data"]).length])
        );
    } else value["customResponses"] = Object.values(value["data"]);
  }

  //render different card content based on question type and whether the card is in edit mode or not
  return (
    <div
      className={` ${
        value["is_highlight"]
          ? "relative rounded-lg bg-white border-2 border-blue-900 mt-2"
          : "relative rounded-lg border bg-white border-1 border-gray-300 mt-2"
      } `}
    >
      <div className="p-4">
        <div className="flex">
          <div className="pl-2 flex w-12 font-normal">
            <div className="text-gray-600">
              <DragHandle />
            </div>
            <div className="ml-2 flex">
              {value["is_highlight"] ? (
                <p className="mb-0 font-semibold" style={{ color: "#0a3859" }}>
                  {serial + 1}.
                </p>
              ) : (
                <p className="mb-0">{serial + 1}.</p>
              )}
            </div>
          </div>
          {toEdit ? (
            <div className="w-11/12 ml-2 mb-1">
              <input
                type="text"
                defaultValue={value.mainTitle}
                placeholder="Enter your question here"
                className="w-11/12 ml-2 mb-1"
                onChange={(event) => {
                  value.mainTitle = event.target.value;
                }}
              ></input>
            </div>
          ) : (
            <div className="w-11/12 ml-2 mb-1">
              <div className="text-sm">
                <Popover content="Click the edit icon to edit the question!">
                  {value.mainTitle === "" ? (
                    <input
                      style={{ resize: "none" }}
                      value={value.mainTitle}
                      placeholder="Enter your question here"
                      className="w-11/12 ml-2 mb-1"
                    />
                  ) : value["is_highlight"] ? (
                    <p
                      className="mb-0 font-semibold"
                      style={{ color: "#0a3859" }}
                    >
                      {value.mainTitle}
                    </p>
                  ) : (
                    <p className="mb-0">{value.mainTitle}</p>
                  )}
                </Popover>
              </div>
            </div>
          )}
          <div className="w-10 ml-4 pb-1">
            <button
              className="py-1 px-2 rounded-md text-xs text-gray-600 border-2"
              onClick={(e) => panelToggle(e.currentTarget)}
            >
              <i
                className={`fa-solid fa-chevron-${PanelOpen ? "up" : "down"}`}
              ></i>
            </button>
          </div>
        </div>
        <hr />
        <div
          className={`container pe-0 ${PanelOpen ? "" : "hidden"}`}
          key={serial}
        >
          <div className="w-8/12">
            {toEdit ? (
              value.quesType === 2 ? (
                <div>
                  <label className="text-xs">{value.quesTypeName} </label>
                  <Slider
                    onChange={(event) => {
                      let temp = {};
                      for (let i = 0; i < event; i++) temp[i + 1] = String(i);
                      value.data = temp;
                    }}
                    min={1}
                    max={10}
                    defaultValue={Object.keys(value.data).length}
                  />
                </div>
              ) : value.quesType === 4 ? (
                <div className="flex">
                  <div className="w-3/4">
                    <div className="mb-4">
                      <label className="text-xs mb-2">
                        {value.quesTypeName}{" "}
                      </label>
                      <div className="mt-2">
                        {Object.keys(value.data).map((item, i) => {
                          return (
                            <div className="flex">
                              <input
                                key={value.data[item]}
                                type="text"
                                className="w-full bg-white text-gray-800 border border-gray-200 py-1 px-2 text-sm rounded mb-1"
                                placeholder="Enter your response here"
                                defaultValue={value.data[item]}
                                onChange={(event) => {
                                  value.data[item] = event.target.value;
                                }}
                              />
                              <div className="ms-2">
                                <button
                                  onClick={() => {
                                    removeFunction(item, serial);
                                  }}
                                  className="rounded py-1 px-2 text-xs "
                                >
                                  <i
                                    className="fa fa-times text-gray-600"
                                    aria-hidden="true"
                                  ></i>
                                </button>{" "}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className="ml-8 w-1/4 flex"
                    style={{ marginTop: "1.25rem" }}
                  >
                    <label className="text-xs mt-4">Rank:</label>
                    <input
                      type="number"
                      className="py-1 text-gray-800 border border-gray-200 px-2 text-sm rounded mb-1 ml-2"
                      defaultValue={value.responseLimit}
                      min={0}
                      onChange={(event) => {
                        value.responseLimit = Number(event.target.value);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={`col-7`}>
                  <div className="mb-4">
                    <label className="text-xs">{value.quesTypeName} </label>
                    <div className="mt-2">
                      {Object.keys(value.data).map((item, i) => {
                        return (
                          <div className="flex">
                            <input
                              key={value.data[item]}
                              type="text"
                              className="block appearance-none w-full bg-white text-gray-800 border border-gray-200 py-1 px-2 text-sm leading-normal rounded mb-1"
                              placeholder="Enter your response here"
                              defaultValue={value.data[item]}
                              onChange={(event) => {
                                value.data[item] = event.target.value;
                              }}
                            />
                            <div className="ms-2">
                              <button
                                onClick={() => {
                                  removeFunction(item, serial);
                                }}
                                className="rounded py-1 px-2 text-xs "
                              >
                                <i
                                  className="fa fa-times text-gray-600"
                                  aria-hidden="true"
                                ></i>
                              </button>{" "}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )
            ) : value.quesType === 2 ? (
              <div>
                <label className="text-xs">{value.quesTypeName} </label>

                <Slider
                  onChange={(event) => {
                    value.data = [event];
                  }}
                  min={1}
                  max={10}
                  defaultValue={Object.keys(value.data).length}
                  disabled
                />
              </div>
            ) : value.quesType === 4 ? (
              <div className="flex">
                <div className="w-3/4">
                  <div className="mb-4">
                    <label className="text-xs">{value.quesTypeName} </label>
                    <div className="mt-2">
                      <Popover content="Click the edit icon to edit the options!">
                        {Object.keys(value.data).map((item, i) => {
                          return (
                            <input
                              type="text"
                              className="block appearance-none w-full bg-white text-gray-800 border border-gray-200 py-1 px-2 text-sm leading-normal rounded mb-1"
                              placeholder="Enter your response here"
                              value={value.data[item]}
                            />
                          );
                        })}
                      </Popover>
                    </div>
                  </div>
                </div>
                <div
                  className="ml-4 w-1/4 flex"
                  style={{ marginTop: "1.25rem" }}
                >
                  <label className="text-xs mt-4">Rank:</label>
                  <input
                    type="number"
                    className="py-1 text-gray-800 border border-gray-200 px-2 text-sm rounded mb-1 ml-2"
                    value={value.responseLimit}
                  />
                </div>
              </div>
            ) : (
              <div className={`col-7`}>
                <div className="mb-4">
                  <label className="text-xs">{value.quesTypeName} </label>
                  <div className="mt-2">
                    <Popover content="Click the edit icon to edit the options!">
                      {Object.keys(value.data).map((item, i) => {
                        return (
                          <input
                            type="text"
                            className="block appearance-none w-full bg-white text-gray-800 border border-gray-200 py-1 px-2 text-sm leading-normal rounded mb-1"
                            placeholder="Enter your response here"
                            value={value.data[item]}
                          />
                        );
                      })}
                    </Popover>
                  </div>
                </div>
              </div>
            )}
            {toEdit ? (
              <div>
                {value.quesType === 1 || value.quesType === 2 ? (
                  ""
                ) : (
                  <span
                    className="text-green-800 mt-4 text-xs"
                    style={{ cursor: "pointer" }}
                    onClick={() => addMoreItmesFunction(serial)}
                  >
                    <i className="fa fa-plus fa-sm" aria-hidden="true"></i> Add
                    Field
                  </span>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => removeCard(serial)}
              className="mr-2 rounded-md py-1 px-2 text-xs text-gray-600 border-2"
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
            {toEdit ? (
              <button
                type="button"
                onClick={() => saveEditCard(serial)}
                className="rounded-md py-1 px-2 text-xs text-gray-600 border-2"
              >
                <i className="fa fa-check" aria-hidden="true"></i>
              </button>
            ) : (
              <Popover
                content={
                  <div className="text-xs w-36">
                    <p className="mb-0">
                      Editing this question will add it in the Question Bank.
                    </p>
                  </div>
                }
              >
                <button
                  type="button"
                  onClick={() => editCard(serial)}
                  className="rounded-md py-1 px-2 text-xs text-gray-600 border-2"
                >
                  <i className="fa fa-edit" aria-hidden="true"></i>
                </button>
              </Popover>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
