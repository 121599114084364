import { Button, Form, Input, message, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import { APP_CONSTANTS } from "../../Utils/Constant";

//component which allows admin to add another user in its environment
function ManagePayer() {
  const [isLoading, setIsLoading] = useState(false);
  const [optn, setOptn] = useState("");
  const [form] = Form.useForm();
  const [payerData, setPayerData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState();
  const [payerRole, setPayerRole] = useState([]);
  const [payerType, setPayerType] = useState([]);
  const { Option } = Select;

  function onFinish(values) {
    //send new user details to DB
    console.log("values", values);
    let tempObject = {
      payer_name: values["name"],
      country: values["country"],
      payer_type: values["payertype"],
      payer_role: values["payerrole"],
      email_address: values["email"],
    };

    setIsLoading(true);
    console.log("user details", tempObject);
    axios
      .post(
        APP_CONSTANTS.API_URL + "/UpdatePayer?operation=" + `${optn}`,
        tempObject
      )
      .then((response) => {
        console.log("response", response);
        if (response.data.status === 200) {
          message.success("Operation Done!");
          form.resetFields();
          form.setFieldsValue({ managePayer: optn });
        } else message.error("Error!");
      })
      .catch((err) => {
        message.error("Error");
        console.log(err);
      })
      .then((res) => {
        setIsLoading(false);
      });
  }

  function getCountries() {
    //get all countries from payer data
    let tempCountries = [];
    for (let i = 0; i < payerData.length; i++) {
      tempCountries.push(payerData[i]["country"]);
    }
    tempCountries = [...new Set(tempCountries)];
    setCountries(tempCountries);
  }

  useEffect(() => {
    // get countries
    getCountries();
  }, [payerData]);

  function selectCountry(value) {
    //update payer type and payer role based on country
    setCountry(value);
    let tempPayerRole = [];
    for (let i = 0; i < payerData.length; i++) {
      if (payerData[i]["country"] === value) {
        tempPayerRole.push(payerData[i]["payerRole"]);
      }
    }
    setPayerRole([...new Set(tempPayerRole)]);
    let tempPayerType = [];
    for (let i = 0; i < payerData.length; i++) {
      if (payerData[i]["country"] === value) {
        tempPayerType.push(payerData[i]["payerType"]);
      }
    }
    setPayerType([...new Set(tempPayerType)]);
    form.setFieldsValue({ payertype: undefined, payerrole: undefined });
  }

  function selectPayerType(value) {
    //update payer role based on payer type
    let tempPayerRole = [];
    for (let i = 0; i < payerData.length; i++) {
      if (
        payerData[i]["payerType"] === value &&
        payerData[i]["country"] === country
      ) {
        tempPayerRole.push(payerData[i]["payerRole"]);
      }
    }
    setPayerRole([...new Set(tempPayerRole)]);
    form.setFieldsValue({ payerrole: undefined });
  }

  const getPayerSummary = async () => {
    let ResultArray = [];
    //API to get all Payer data across all countries
    await axios
      .get(APP_CONSTANTS.API_URL + "/PayerSummary")
      .then((res) => {
        const result = res.data.result;
        ResultArray = result;
        console.log(result);
      })
      .catch((err) => {
        console.log({ err });
      });
    return ResultArray;
  };

  function onDDChange(e) {
    if (optn !== e) {
      setOptn(e);
      form.resetFields();
      form.setFieldsValue({ managePayer: e });
    }
  }

  useEffect(() => {
    // get payer summary
    window.scrollTo(0, 0);
    getPayerSummary().then((output) => {
      setIsLoading(false);
      setPayerData(output);
    });
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "65.5vh" }}
        >
          <div>
            <div className="flex justify-center items-center">
              <ScaleLoader
                color="teal"
                height={60}
                width={6}
                radius={18}
                margin={2}
              />
            </div>
            <p className="text-black font-semibold text-xl mt-4">
              Updating User. Please wait!
            </p>
          </div>
        </div>
      ) : (
        <Form
          name="userDetails"
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={onFinish}
        >
          <div className="pl-40 pr-60 mt-32">
            <Form.Item
              label="Manage Payer"
              name="managePayer"
              rules={[
                {
                  required: true,
                  message: "Please select a operation!",
                },
              ]}
            >
              <Select value={optn} onChange={(e) => onDDChange(e)}>
                <Select.Option value="add">Add Payer</Select.Option>
                <Select.Option value="remove">Remove Payer</Select.Option>
                <Select.Option value="freeze">Freeze Payer</Select.Option>
                <Select.Option value="unfreeze">Unfreeze Payer</Select.Option>
              </Select>
            </Form.Item>
            {(optn === "add" ||
              optn === "remove" ||
              optn === "freeze" ||
              optn === "unfreeze") && (
              <div>
                <Form.Item
                  label="Payer Name"
                  name={"name"}
                  rules={[{ required: true, message: "Please Enter Name!" }]}
                >
                  <Input placeholder="Enter Name" />
                </Form.Item>
                <Form.Item
                  label="Payer Email"
                  name={"email"}
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please Enter E-mail ID!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Email-ID" />
                </Form.Item>
                {(optn === "add" ||
                  (optn === "remove" &&
                    optn === "freeze" &&
                    optn === "unfreeze")) && (
                  <div>
                    <Form.Item
                      label="Payer Country"
                      name={"country"}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Payer Country!",
                        },
                      ]}
                    >
                      {/* <Input placeholder="Enter Payer Country" /> */}
                      <Select onSelect={selectCountry}>
                        {countries.map((country) => {
                          return <Option value={country}>{country}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Payer Type"
                      name={"payertype"}
                      rules={[
                        { required: true, message: "Please Enter Payer Type!" },
                      ]}
                    >
                      {/* <Input placeholder="Enter Payer Type" /> */}
                      <Select onSelect={selectPayerType}>
                        {payerType.map((type) => {
                          return <Option value={type}>{type}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Payer Expertise"
                      name={"payerrole"}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Payer Expertise!",
                        },
                      ]}
                    >
                      {/* <Input placeholder="Enter Payer Expertise’" /> */}
                      <Select>
                        {payerRole.map((role) => {
                          return <Option value={role}>{role}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                    {/* <Form.Item
                      label="Payer Email"
                      name={"email"}
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please Enter E-mail ID!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Email-ID" />
                    </Form.Item> */}
                  </div>
                )}
                <Form.Item>
                  <Button
                    className="mx-96"
                    style={{
                      width: "8rem",
                      // marginLeft: "1rem",
                      marginTop: "1rem",
                    }}
                    type="primary"
                    htmlType="submit"
                    name="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            )}
          </div>
        </Form>
      )}
    </div>
  );
}

export default ManagePayer;
